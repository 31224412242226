<template>
  <h1>Instellingen</h1>
  <div class="smoothContainer">
      <h5>
        Mijn profiel
      </h5>

      <div class="row mt-5">
        <div class="col-lg-4 col-sm-12 text-center">
          <h6>
            {{ this.$store.state.user.name }}
          </h6>
          Klik op je avatar om een nieuwe afbeelding te kiezen:<br><br>
          <div class="avatar rounded-circle text-center" style="cursor: pointer; border: 3px solid #999999; " v-bind:style="{ backgroundImage: 'url(' + this.$store.state.user.avatar + ')' }">
            <input type="file" @change="handleAvatarFileSelect" style="cursor: pointer; opacity: 0; width:100%; height:100%">
          </div>
        </div>

        <div class="col-lg-4 col-sm-12">
          <h6>Veranderd wachtwoord</h6>
          <form @submit.prevent="changePassword">
            <div class="form-group">
              <label for="currentPassword">Huidige wachtwoord</label>
              <input type="password" id="currentPassword" class="form-control" v-model="currentPassword" required />
            </div>
            <div class="form-group">
              <label for="newPassword">Nieuw wachtwoord</label>
              <input type="password" id="newPassword" class="form-control" v-model="newPassword" required />
            </div>
            <div class="form-group mb-2">
              <label for="confirmPassword">Bevestig wachtwoord</label>
              <input type="password" id="confirmPassword" class="form-control" v-model="confirmPassword" required />
            </div>
            <button class="btn btn-primary mb-2">Wijzigen</button>
          </form>

          <div v-if="passwordsMatch == false" class="text-center alert alert-danger">Bevestiging wachtwoord komt niet overeen</div>
          <div v-if="passwordError != ''" class="text-center alert alert-danger">{{passwordError}}</div>
          <div v-if="passwordChanged != false" class="text-center alert alert-success">{{passwordError}}</div>
        </div>

        <div class="col-lg-4 col-sm-12">
          <h6>Automatische berichten</h6>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="subscribed" @change="saveSubscribed()">
            <label class="form-check-label" for="flexSwitchCheckDefault"> Wekelijks rapport</label>
          </div>
        </div>

      </div>
  </div>
  <div class="smoothContainer admin" v-if="this.$store.state.user.admin">
    <h5>
      Groepering templates
    </h5>

    <div class="smoothContainer">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#" @click="activeDomain=null">Hoofdgroepering</a></li>
        <li class="breadcrumb-item" v-if="activeDomain" @click="selectDomain(activeDomain)"><a href="#">{{activeDomain.name}}</a></li>
        <li class="breadcrumb-item" v-if="activeDomain && activeDomain.activeSubdomain" @click="selectSubdomain(activeDomain.activeSubdomain)"><a href="#">{{activeDomain.activeSubdomain.name}}</a></li>
        <li class="breadcrumb-item active" v-if="activeDomain && activeDomain.activeSubdomain && activeDomain.activeSubdomain.activeLine">{{activeDomain.activeSubdomain.activeLine.name}}</li>
      </ol>  

      <!--List all domains-->
      <ul class="list-group" v-if="!activeDomain">
        <li class="list-group-item">
          <button class="btn btn-primary me-2" @click="addDomain()"><fa icon="fa-solid fa-plus" />  Nieuw domein</button>
          <button class="btn btn-primary" @click="saveDomainSettingsAndOrder()">Opslaan</button>
        </li>
        <li v-for="(domain, domainIndex) in domains" :key=domainIndex class="list-group-item">
          <a href="#" @click="selectDomain(domain)">{{domain.name}}</a> 
          <a href="#" class="ms-1" @click="renameDomain(domain)"><fa icon="fa-solid fa-pen-to-square" /></a>

          <!--Toggle buttons to change between activeDomain.forBso and activeDomain.forSchool-->
          <div class="btn-group" role="group" aria-label="Domain type">
            <button type="button" class="btn ms-3 me-2" :class="{'btn-success': domain.forBso, 'btn-outline-secondary': !domain.forBso}" @click="toggleDomainBso(domain)">BSO's</button>
            <button type="button" class="btn me-3" :class="{'btn-success': domain.forSchool, 'btn-outline-secondary': !domain.forSchool}" @click="toggleDomainSchool(domain)">Scholen</button>
          </div>

          <a href="#" class="tinyRemove text-danger ms-3" @click="removeDomain(domain)">
              <fa icon="fa-solid fa-remove" />
          </a>

          <!-- Arrow buttons to move domain up or down in domains array -->
          <a v-if="domainIndex > 0" role="button" class="text-decoration-none me-2 ms-2 float-end"
            @click="domains.splice(domainIndex - 1, 0, domains.splice(domainIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-up" />
          </a>
          <a v-if="domainIndex < domains.length - 1" role="button" class="text-decoration-none me-2 ms-2 float-end"
            @click="domains.splice(domainIndex + 1, 0, domains.splice(domainIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-down" />
          </a>
        </li>
      </ul>

      <!--List subdomains in selected domain-->
      <ul class="list-group" v-else-if="activeDomain && activeDomain.subdomains && !activeDomain.activeSubdomain">
        <li class="list-group-item">
          <button class="btn btn-primary me-2" @click="addSubdomain()"><fa icon="fa-solid fa-plus" /> Nieuw subdomein</button>
          <button class="btn btn-primary" @click="saveSubdomainOrder()">Volgorde opslaan</button>
        </li>
        <li v-for="(subdomain, subdomainIndex) in activeDomain.subdomains" :key=subdomainIndex class="list-group-item">
          <a href="#" @click="selectSubdomain(subdomain)" >{{subdomain.name}}</a>
          <a href="#" class="ms-1" @click="renameSubdomain(subdomain)"><fa icon="fa-solid fa-pen-to-square" /></a>
          <a href="#" class="tinyRemove text-danger ms-3" @click="removeSubdomain(subdomain)">
              <fa icon="fa-solid fa-remove" />
          </a>

          <!-- Arrow buttons to move subdomain up or down in subdomains array -->
          <a v-if="subdomainIndex > 0" role="button" class="text-decoration-none me-2 ms-2 float-end"
            @click="activeDomain.subdomains.splice(subdomainIndex - 1, 0, activeDomain.subdomains.splice(subdomainIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-up" />
          </a>
          <a v-if="subdomainIndex < activeDomain.subdomains.length - 1" role="button"
            class="text-decoration-none me-2 ms-2 float-end"
            @click="activeDomain.subdomains.splice(subdomainIndex + 1, 0, activeDomain.subdomains.splice(subdomainIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-down" />
          </a>
        </li>
      </ul>

      <!--List learning lines in selected subdomain-->
      <ul class="list-group" v-else-if="activeDomain && activeDomain.activeSubdomain && activeDomain.activeSubdomain.lines && !activeDomain.activeSubdomain.activeLine">
        <li class="list-group-item">
          <button class="btn btn-primary me-2" @click="addLine()"><fa icon="fa-solid fa-plus" /> Nieuwe leerlijn</button>
          <button class="btn btn-primary" @click="saveLineOrder()">Volgorde opslaan</button>
        </li>

        <li v-for="(line, lineIndex) in activeDomain.activeSubdomain.lines" :key=lineIndex class="list-group-item">
          <a href="#" @click="selectLine(line)">{{line.name}}</a>
          <a href="#" class="ms-1" @click="renameLine(line)"><fa icon="fa-solid fa-pen-to-square" /></a>

          <a href="#" class="tinyRemove text-danger ms-3" @click="removeLine(line)">
            <fa icon="fa-solid fa-remove" />
          </a>
          
          <!--Arrow buttons to move line up or down in lines array-->
          <a v-if="lineIndex > 0" role="button" class="text-decoration-none me-2 ms-2 float-end"
            @click="activeDomain.activeSubdomain.lines.splice(lineIndex - 1, 0, activeDomain.activeSubdomain.lines.splice(lineIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-up" />
          </a>
          <a v-if="lineIndex < activeDomain.activeSubdomain.lines.length - 1" role="button"
            class="text-decoration-none me-2 ms-2 float-end"
            @click="activeDomain.activeSubdomain.lines.splice(lineIndex + 1, 0, activeDomain.activeSubdomain.lines.splice(lineIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-down" />
          </a>          
        </li>
      </ul>

      <!--List setting templates in selected line-->
      <ul class="list-group" v-else-if="activeDomain && activeDomain.activeSubdomain && activeDomain.activeSubdomain.activeLine && activeDomain.activeSubdomain.activeLine.settingTemplates">
        <li class="list-group-item">
          <button class="btn btn-primary" @click="saveSettingTemplateOrder()">Volgorde opslaan</button>
        </li>
        <li v-for="(settingTemplate, settingTemplateIndex) in activeDomain.activeSubdomain.activeLine.settingTemplates" :key=settingTemplateIndex class="list-group-item">
          <span>{{settingTemplate.name}}</span>

          <!--Arrow buttons to move setting template up or down in setting templates array-->
          <a v-if="settingTemplateIndex > 0" role="button" class="text-decoration-none me-2 ms-2 float-end"
            @click="activeDomain.activeSubdomain.activeLine.settingTemplates.splice(settingTemplateIndex - 1, 0, activeDomain.activeSubdomain.activeLine.settingTemplates.splice(settingTemplateIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-up" />
          </a>
          <a v-if="settingTemplateIndex < activeDomain.activeSubdomain.activeLine.settingTemplates.length - 1" role="button"
            class="text-decoration-none me-2 ms-2 float-end"
            @click="activeDomain.activeSubdomain.activeLine.settingTemplates.splice(settingTemplateIndex + 1, 0, activeDomain.activeSubdomain.activeLine.settingTemplates.splice(settingTemplateIndex, 1)[0]);">
            <fa icon="fa-solid fa-arrow-down" />
          </a>
        </li>
      </ul>
    </div>
</div>

</template>

<script>
import { auth, db, firebase } from '@/main';
import Papa from 'papaparse';
import defaultAvatar from "@/assets/images/avatar.png";

export default {
  name: 'SettingsModule',
  data(){
    return {
      domains: [],
      subscribed: true,
      activeDomain: null,
      lastActivities: [],
      passwordChanged: false,
      stagedImport: null,
      stagedImportFileName: "",
      passwordsMatch: true,
      passwordError: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      targetSchoolID: "",
      createUserError :"",
      avatarImage: "data:image/png;charset=utf-8;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8cfz4fwAIDANXXwf1NwAAAABJRU5ErkJggg==" //Grey pixel
    }
  },
  methods:{
    removeDomain(domain){
      if(!confirm("Weet je zeker dat je dit domein wilt verwijderen?"))
      {
        return;
      }

      db.collection("/domains").doc(domain.id).delete()
      .then(() => {
        console.log("Removed domain");
        this.getDomains();
      })
      .catch((error) => {
        console.log("Error removing domain: ", error);
      });
    },
    removeSubdomain(subdomain){
      if(!confirm("Weet je zeker dat je dit subdomein wilt verwijderen?"))
      {
        return;
      }

      db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(subdomain.id).delete()
      .then(() => {
        console.log("Removed subdomain");
        this.selectDomain(this.activeDomain);
      })
      .catch((error) => {
        console.log("Error removing subdomain: ", error);
      });
    },
    removeLine(line){
      if(!confirm("Weet je zeker dat je deze leerlijn wilt verwijderen?"))
      {
        return;
      }

      db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines').doc(line.id).delete()
      .then(() => {
        console.log("Removed line");
        this.selectSubdomain(this.activeDomain.activeSubdomain);
      })
      .catch((error) => {
        console.log("Error removing line: ", error);
      });
    },
    addDomain(){
      let newName = prompt("Naam voor nieuw domein");
      if(newName)
      {
        let newDomain = {
          name: newName,
          forBso: false,
          forSchool: true,
          order: 0
        }
        
        db.collection("/domains").add(newDomain)
        .then((docRef) => {
            console.log("Added domain with ID: ", docRef.id);
            this.getDomains();
        })
        .catch((error) => {
            console.error("Error adding domain: ", error);
        });
      }
    },
    addSubdomain(){
      let newName = prompt("Naam voor nieuw subdomein");
      if(newName)
      {
        let newSubdomain = {
          name: newName,
          domainId: this.activeDomain.id,
          order: 0
        }
        db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').add(newSubdomain)
        .then((docRef) => {
            console.log("Added subdomain with ID: ", docRef.id);
            this.selectDomain(this.activeDomain);
        })
        .catch((error) => {
            console.error("Error adding subdomain: ", error);
        });
      }
    },
    toggleDomainBso(domain){
      domain.forBso = !domain.forBso;
      domain.forSchool = !domain.forBso ? true : false;
    },
    toggleDomainSchool(domain){
      domain.forSchool = !domain.forSchool;
      domain.forBso = !domain.forSchool ? true : false;
    },
    addLine(){
      let newName = prompt("Naam voor nieuwe leerlijn");
      if(newName)
      {
        let newLine = {
          name: newName,
          domainId: this.activeDomain.id,
          subdomainId: this.activeDomain.activeSubdomain.id,
          order: 0
        };
        db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines').add(newLine)
        .then((docRef) => {
          console.log("Added line with ID: ", docRef.id);
          this.selectSubdomain(this.activeDomain.activeSubdomain);
        })
        .catch((error) => {
          console.error("Error adding line: ", error);
        });
      }
    },
    saveDomainSettingsAndOrder(){
      this.domains.forEach((domain, index) => {
        db.collection("/domains").doc(domain.id).set(
          {
            order: index,
            forBso: domain.forBso,
            forSchool: domain.forSchool
          }, { merge: true }
        )
        .then(() => {
          console.log("Saved domain order");
        })
        .catch((error) => {
          console.log("Error saving domain order: ", error);
        });
      });
    },
    saveSubdomainOrder(){
      this.activeDomain.subdomains.forEach((subdomain, index) => {
        db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(subdomain.id).set(
          {
            order: index,
            domainId: this.activeDomain.id
          }, { merge: true }
        )
        .then(() => {
          console.log("Saved subdomain order");
        })
        .catch((error) => {
          console.log("Error saving subdomain order: ", error);
        });
      });
    },
    saveLineOrder(){
      this.activeDomain.activeSubdomain.lines.forEach((line, index) => {
        db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines').doc(line.id).set(
          {
            order: index,
            domainId: this.activeDomain.id,
            subdomainId: this.activeDomain.activeSubdomain.id
          }, { merge: true })
        .then(() => {
          console.log("Saved line order");
        })
        .catch((error) => {
          console.log("Error saving line order: ", error);
        });
      });
    },
    saveSettingTemplateOrder(){
      this.activeDomain.activeSubdomain.activeLine.settingTemplates.forEach((settingTemplate, index) => {
        db.collection('/settingTemplates').doc(settingTemplate.id).set({order: index}, { merge: true })
        .then(() => {
          console.log("Saved setting template order");
        })
        .catch((error) => {
          console.log("Error saving setting template order: ", error);
        });
      });
    },
    renameDomain(domain){
      let newName = prompt("Nieuwe naam voor domein", domain.name);
      if(newName)
      {
        db.collection("/domains").doc(domain.id).set({name: newName}, { merge: true })
        .then(() => {
          console.log("Renamed domain");
          domain.name = newName;
        })
        .catch((error) => {
          console.log("Error renaming domain: ", error);
        });
      }
    },
    renameSubdomain(subdomain){
      let newName = prompt("Nieuwe naam voor subdomein", subdomain.name);
      if(newName)
      {
        db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(subdomain.id).set({name: newName}, { merge: true })
        .then(() => {
          console.log("Renamed subdomain");
          subdomain.name = newName;
        })
        .catch((error) => {
          console.log("Error renaming subdomain: ", error);
        });
      }
    },
    renameLine(line){
      let newName = prompt("Nieuwe naam voor leerlijn", line.name);
      if(newName)
      {
        db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines').doc(line.id).set({name: newName}, { merge: true })
        .then(() => {
          console.log("Renamed line");
          line.name = newName;
        })
        .catch((error) => {
          console.log("Error renaming line: ", error);
        });
      }
    },
    getDomains(){
      db.collection("/domains").get().then((items) => {
            console.log(items);
            let _domains = [];
            items.forEach((item) => {
              let _domain = item.data();
              _domain.id = item.id;
              _domains.push(_domain);
            });

            //order domains by order field (if it exists)
            _domains.sort((a, b) => (a.order > b.order) ? 1 : -1);

            this.domains = _domains;
          }
        );
    },
    selectDomain(domain){
      this.activeDomain = domain;

      this.activeDomain.forBso = this.activeDomain.forBso || false;
      this.activeDomain.forSchool = this.activeDomain.forSchool || true;

      if(this.activeDomain.activeSubdomain)
      {
        if(this.activeDomain.activeSubdomain.activeLine)
        {
          this.activeDomain.activeSubdomain.activeLine = null;
        }
        this.activeDomain.activeSubdomain = null;
      }
      db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').get().then((items) => {
            console.log(items);
            let _subdomains = [];
            items.forEach((item) => {
              let _subdomain = item.data();
              _subdomain.id = item.id;
              _subdomains.push(_subdomain);
            });

            //order subdomains by order field (if it exists)
            _subdomains.sort((a, b) => (a.order > b.order) ? 1 : -1);

            this.activeDomain.subdomains = _subdomains;
          }
        );
    },
    selectSubdomain(subdomain){
      this.activeDomain.activeSubdomain = subdomain;
      if(this.activeDomain.activeSubdomain.activeLine)
      {
        this.activeDomain.activeSubdomain.activeLine = null;
      }

      db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines').get().then((items) => {
            console.log(items);
            let _lines = [];
            items.forEach((item) => {
              let _line = item.data();
              _line.id = item.id;
              _lines.push(_line);
            });

            //order lines by order field (if it exists)
            _lines.sort((a, b) => (a.order > b.order) ? 1 : -1);

            this.activeDomain.activeSubdomain.lines = _lines;
          }
        );
    },
    selectLine(line){
      this.activeDomain.activeSubdomain.activeLine = line;

      //Select all settingTemplates documents that have lineId == line.id
      db.collection("/settingTemplates").where("lineId", "==", line.id).get().then((items) => {
            console.log(items);
            let _settingTemplates = [];
            items.forEach((item) => {
              let _settingTemplate = item.data();
              _settingTemplate.id = item.id;
              _settingTemplates.push(_settingTemplate);
            });

            //Order settingTemplates by order field (if it exists)
            _settingTemplates.sort((a, b) => (a.order > b.order) ? 1 : -1);

            this.activeDomain.activeSubdomain.activeLine.settingTemplates = _settingTemplates;
          }
        );
    },
    saveSubscribed(){
      console.log("Saving subscribed " + this.subscribed);
      var userRef = db.collection("users").doc(auth.currentUser.uid);
      userRef.set({subscribed: this.subscribed}, { merge: true })
      .then(() => {
        console.log("Saved subscribed");
      })
      .catch((error) => {
        console.log("Error updating or getting user document: ", error);
      });
    },
    fixLastPlayed(lastActivity)
    {
      var userRef = db.collection("/schools").doc(lastActivity.schoolId).collection("students").doc(lastActivity.studentId);
      userRef.set({activityId: lastActivity.id,}, { merge: true })
      .then(() => {
        lastActivity.fixed = true;
        console.log("Fixed " + lastActivity.student.name);
      })
    },
    getLastActivities() {
      db.collection("/activities")
        .orderBy('started', 'desc')
        .where('started', '>', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)) // 30 days
        .get()
        .then((items) => {
          console.log(items);
          let _activities = [];
          items.forEach((item) => {
            let _activity = item.data();
            _activity.id = item.id;
            _activity.results = [];
            _activity.needsSave = false;
            _activities.push(_activity);

            let studentId = _activity.studentId;
            let schoolId = _activity.schoolId;

            // Get /activities/{activityId}/results 
            db.collection("/activities")
              .doc(_activity.id)
              .collection("results")
              .get()
              .then((results) => {
                results.forEach((resultDoc) => {
                  let result = resultDoc.data();
                  result.id = resultDoc.id;
                  result.needsSave = false;
                  _activity.results.push(result);
                  let settingTemplateId = result.settingTemplateId;
                  
                  // Check if doc schools/{schoolId}/students/{studentId}/save/{settingTemplateId} exists and if level is higher or equal to result.level
                  db.collection("/schools")
                    .doc(schoolId)
                    .collection("students")
                    .doc(studentId)
                    .collection("save")
                    .doc(settingTemplateId)
                    .get()
                    .then((saveDoc) => {
                      if (saveDoc.exists) {
                        let save = saveDoc.data();
                        if (save.level && save.level < result.level) {
                          result.needsSave = true;
                          _activity.needsSave = true;
                        }
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                });
              })
              .catch((error) => {
                console.error(error);
              });
          });

          this.lastActivities = _activities;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatActivity(activity)
    {
      return activity.started.toDate().toLocaleDateString() + " " + activity.started.toDate().toLocaleTimeString();
    },
    changePassword() {
      this.passwordsMatch = true;
      this.passwordChanged = false;

      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        this.currentPassword
      );

      auth.currentUser.reauthenticateWithCredential(credential).then(() =>{
        if (this.newPassword === this.confirmPassword) {
          auth.currentUser.updatePassword(this.newPassword).then(()=>
          {
            this.passwordChanged = true;

          });
        } else {
          this.passwordsMatch = false;
        }
      }).catch((error)=>{
        this.passwordError = error.message;
      });
    },
    handleAvatarFileSelect(event) {
      if(event.target.files.length < 1)
      {
        console.log("Cancelled avatar select");
        return;
      }

      const file = event.target.files[0];

      // Create a new Image object
      const img = new Image();

      // Create a FileReader object
      const reader = new FileReader();

      // Add an event listener for when the image is loaded
      img.addEventListener("load", () => {
        // Set the maximum width and height
        const maxWidth = 200;
        const maxHeight = 200;

        // Calculate the new width and height
        let width = img.width;
        let height = img.height;
        if (width > height && width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
        } 
        else if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
        }

        // Draw avatar on canvas
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // Get the base64 string of the resized image
        const base64 = canvas.toDataURL("image/jpeg", 0.8);
        this.saveUserAvatar(base64);
      });

      // Add an event listener for when the file is read
      reader.addEventListener("load", () => {
        // Set the src of the Image object to the data URL of the file
        img.src = reader.result;
      });

      // Read the file as a data URL
      reader.readAsDataURL(file);
    },
    userDefaultAvatar(){
        //Default avatar if none is found
        if(!this.$store.state.user.avatar)
        {
            this.$store.state.user.avatar = defaultAvatar;
        }
    },
    saveUserAvatar(imageBase64){
      let prefixedBase64 = imageBase64;

      console.log("Saving user info " + auth.currentUser.uid);
      var userRef = db.collection("users").doc(auth.currentUser.uid);
      userRef.set({avatar: prefixedBase64,}, { merge: true })
      .then(() => {
        console.log("Saved avatar");
        this.$store.state.user.avatar = prefixedBase64;
      })
      .catch((error) => {
        console.log("Error updating or getting user document: ", error);
      });
    },
    //DEPRECATED importCSV NOW WE DO NOT USE THE goals COLLECTION ANYMORE
    //This used to import the goals from a CSV file
    //Now setting templates are our mail 'goals'
    importCSV(){ 
      const input = this.$refs.csv;
      const file = input.files[0];
      this.stagedImportFileName = file.name.split('.').slice(0, -1).join('.');

      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
            const result = Papa.parse(event.target.result,{
              header: false //By setting this to false, the values are arrays instead of objects mapped by header
            }
          );
          this.stagedImport = result.data;
        };

        reader.readAsText(file);
      }
      input.value = "";
    },
    async setLearningLines() {
      let docRefLine = null;
      let docRefGoal = null;
      console.log("Exporting " + this.stagedImport.length);
      let ordering = 0;

      for (const column of this.stagedImport) {
        if (column[0].length < 1 && column[1].length > 1 && column.length >= 2) {
          console.log(column);
          ordering++;
          
          const learningline = {
            name: column[1],
            order: ordering,
            parentId: "",
            domainId: this.activeDomain.id,
            subdomainId: this.activeDomain.activeSubdomain.id,
          };

          const linesRef = db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines');
          const existingLinesQuery = await linesRef.where("name", '==', learningline.name).get();
          if (!existingLinesQuery.empty) {
            console.log("Updating existing line " + learningline.name);
            docRefLine = existingLinesQuery.docs[0].ref;
            await docRefLine.update(learningline);
          }
          else{
            docRefLine = db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines').doc();
            await docRefLine.set(learningline);
            console.log("Write new learning line " + learningline.name);
          }
        } 
        else if (docRefLine && column[1].length > 1) {
          ordering++;

          const goal = {
            purpose: column[0],
            name: column[1],
            short: column[2],
            rn: column[3],
            year: column[4],
            order: ordering,
            domainId: this.activeDomain.id,
            subdomainId: this.activeDomain.activeSubdomain.id,
            lineId: docRefLine.id
          };
          
          //Check if goal with this name already exists
          const goalsCollectionRef = db.collection("/goals");
          const exisitingGoalQuery = await goalsCollectionRef.where("name", '==', goal.name).get();
          if (!exisitingGoalQuery.empty) {
            console.log("Updating existing goal " + goal.name);
            await exisitingGoalQuery.docs[0].ref.update(goal);
          }
          else{
            console.log("Creating new goal " + goal.name);
            docRefGoal = goalsCollectionRef.doc();
            await docRefGoal.set(goal);
          }
        }
      }
    }
    ,
    uploadEntireCollection(collection){
      let docRef = null;
      console.log("Exporting " + this.stagedImport.length);
      let ordering = 0;

      this.stagedImport.forEach((column) => {
        //Empty column on 0, use title as new main goal?
        if(column[0].length < 1 && column[1].length > 1 && column.length >= 2)
        {
          console.log(column)
          ordering++;
          //Write it
          let goal = {
            name: column[1],
            short: column[2],
            year: column[3],
            order: ordering,
            parentId: "",
            mainGoal: this.stagedImportFileName
          }

          docRef = db.collection(collection).doc();
          docRef.set(goal);         

          console.log("Write main goal " + goal.name);
        }
        else if(docRef && column[1].length > 1){
          ordering++;

          //Might want to batch these
          let subGoal = {
            name: column[1],
            short: column[2],
            order: ordering,
            parentId: docRef.id
          }
          const subGoalDoc = db.collection(collection).doc();
          subGoalDoc.set(subGoal); 

          console.log("Write sub goal " + subGoal.name);
       }
      });
    }
  },
  mounted() {
    this.userDefaultAvatar();
    if(this.$store.state.user.admin){
      this.getDomains();
    }
  }
};
</script>
