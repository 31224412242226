import { createStore } from 'vuex'

const store = createStore({
    state () {
      return {
        groupId:"",
        schoolId: "",
        showBsoFeatures: false,
        showSchoolFeatures: true,
        freshLogin: false,
        loginRedirect: "",
        admin: false,
        user: null,
        loggedUser: null,
        showMenu: false
      }
    }
});

// export the store
export default store