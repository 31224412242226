<template>
  <h1>Dashboard</h1>
  <h2 class="display-6">{{ school.name }} - <small class="text-muted">{{ user.name }}
      <fa v-if="user.admin" icon="fa-solid fa-user-cog" />
    </small></h2><br>

  <div class="smoothContainer">
    <h5>Gebruikers</h5><br>
    <div v-if="lastLoggedUsers.length > 0">
      <div :title="loggedUser.lastLogin" class="text-center" style="margin: 5px; display:inline-block; width: 80px;"
        v-for="(loggedUser, loggedUserIndex) in lastLoggedUsers" :key="loggedUserIndex">
        <h6>{{ loggedUser.name }}</h6>
        <div class="animateIn avatar rounded-circle coinAvatar"
          style="display:inline-block; width:50px; height: 50px; "
          v-bind:style="{ backgroundImage: 'url(' + (loggedUser.avatar) + ')' }">
        </div>
        <br>
      </div>
    </div>
  </div>

  <div class="smoothContainer admin" v-if="showMedianResults && user.admin">
    <h5>Progressie</h5><br>
    <div>
      <div v-if="!showChart" class="d-flex justify-content-center mb-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Laden...</span>
        </div>
      </div>
      <line-chart v-if="showChart" class="progressChart" :data="chartData" :options="chartOptions"></line-chart>
    </div>
  </div>

  <div class="smoothContainer admin" v-if="user.admin">
    <h5>Activiteiten</h5><br>
    <div>
      <table class="table outerTable">
        <thead>
          <tr>
            <th>School</th>
            <th>Laatste week</th>
            <th>Laatste maand</th>
            <th>Groepen actief</th>
          </tr>
        </thead>
        <tbody v-if="!loadingSchoolStats">
          <tr v-for="(school, schoolIndex) in schools" :key="schoolIndex"  @click="school.showGroups = !school.showGroups" :class="{'activeSchool':(schoolId == school.id)}">
            <td colspan="4">
              <table class="table innerTable">
                <tbody>
                  <tr :class="{'table-warning': school.uniqueStudentsThisWeek.length < 4}">
                    <td>{{ school.name }}</td>
                    <td><b>{{ school.activitiesThisWeek.length }} <small class="text-body-tertiary"> / {{ school.uniqueStudentsThisWeek.length }}</small></b></td>
                    <td><b>{{ school.activities.length }} <small class="text-body-tertiary"> / {{ school.uniqueStudents.length }}</small></b></td>
                    <td>
                      <b :class="{'text-warning': school.groupWarning}">{{ school.uniqueGroups.length }} / {{ school.groups.length }} <span class=""><fa v-show="school.groupWarning" icon="fa-solid fa-triangle-exclamation" /></span></b>
                    </td>
                  </tr>
                  <tr v-show="school.showGroups" v-for="(group, groupIndex) in school.groups" :key="groupIndex" class="groupRow" :class="{'table-warning': group.groupWarning, 'table-light': !group.groupWarning}">
                    <td>{{ group.name }}</td>
                    <td><b>{{ group.playedThisWeek.length }} <small class="text-body-tertiary">/ {{ group.playedThisWeekUniqueStudents.length }} </small> <fa :class="{'text-warning': group.groupWarning}" v-show="group.groupWarning" icon="fa-solid fa-triangle-exclamation" /></b></td>
                    <td><b>{{ group.playedThisMonth.length }} <small class="text-body-tertiary">/ {{ group.playedThisMonthUniqueStudents.length }}</small></b></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <LoadingSpinner :loading="loadingSchoolStats" />
    </div>
  </div>

  <div class="smoothContainer">
    <h5>Support</h5><br>

    <p>
        Heb je een vraag?<br>
        Neem contact op met Arnoud via <a href="mailto:info@yuha.io" target="_blank">support@yuha.io</a><br>
        of bel naar +31 6 8292 7258
    </p>
  </div>
</template>

<script>
import { auth, db } from '@/main'
import { router } from '@/router';
import LoadingSpinner from './Nested/LoadingSpinner.vue';
import defaultAvatar from "@/assets/images/avatar.png";

export default {
  name: "DashboardModule",
  data() {
    return {
      user: {
        id: "",
        schoolId: "",
        admin: false
      },
      showMedianResults: false,
      schoolId: 0,
      school: {},
      loadingSchoolStats: true,
      lastLoggedUsers: [],
      activities: [],
      schools:[],
      schoolActivities: [],
      chartActivities: [],
      showChart: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            //beginAtZero: true,
            max: 100
          }
        },
        maintainAspectRatio: false,
        tension: 0.5,
        plugins: {
          datalabels: {
              display: false,
          },
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 15,
              // This more specific font property overrides the global property
              font: {
                size: 14
              }
            }
          }
        }
      }
    };
  },
  components: { LoadingSpinner },
  mounted() {
    this.checkLoggedUserSchool();

    if(this.user.admin){
      this.getAdminDashboardData();
    }
  },
  methods: {
    checkLoggedUserSchool() {
      this.user = this.$store.state.user;
      this.schoolId = this.$store.state.user.schoolId;

      //Log if admin 
      console.log("Userdata in dashboard", this.user);

      if (this.schoolId == null || this.schoolId == '') {
        auth.signOut();
        router.replace('/login');
        return;
      }

      //Override school if user is admin and picked another school
      if (this.$store.state.user != null &&
        this.$store.state.user.id == this.user.id &&
        this.$store.state.schoolId != '' &&
        this.$store.state.user.schoolId != this.$store.state.schoolId
      ) 
      {
        console.log("Override school is used")
        this.schoolId = this.$store.state.schoolId;
      }
      this.$store.state.schoolId = this.schoolId;

      //Show other users for this school
      this.loadUsersForSchool();

      //Get the assigned main school
      db.collection("/schools").doc(this.schoolId).get().then((doc) => this.onGotSchoolData(doc));
    },
    getActivitiesToday() {
      // Get the current date
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 7);

      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);

      // Define a Firestore query to get the documents that have a 'started' field with a timestamp between the start and end of today
      db.collection('activities')
        .where('started', '>=', lastMonth)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let _activity = doc.data();
            _activity.id = doc.id;
            let schoolId = _activity.schoolId;

            //Get school from schools that has id
            let school = this.schools.find(s => s.id == schoolId);
            school.activities.push(_activity);
          });

          //Order schools by activity count
          this.schools.sort((a, b) => (a.activities.length < b.activities.length) ? 1 : -1);

          //Get unique students and groups
          this.schools.forEach(school => {
              let uniqueStudents = [];
              school.groupWarning = false;
              school.activities.forEach(activity => {
                if (!uniqueStudents.includes(activity.studentId)) {
                  uniqueStudents.push(activity.studentId);
                }
              });
              school.uniqueStudents = uniqueStudents;

              let uniqueGroups = [];
              school.activities.forEach(activity => {
                //Set the schools.groups group.played = true
                let group = school.groups.find(g => g.id == activity.groupId);
                if(group){
                  group.played = true;
                }
                
                //Add to our unique groups list
                if (!uniqueGroups.includes(activity.groupId)) {
                  uniqueGroups.push(activity.groupId);
                }
              });
              school.uniqueGroups = uniqueGroups;

              //For school.groups set the group.playedThisWeek and group.playedThisMonth
              school.groups.forEach(group => {
                let groupActivitiesThisWeek = school.activities.filter(a => a.groupId == group.id && new Date(a.started.toDate()) >= lastWeek);
                group.playedThisWeek = groupActivitiesThisWeek;
                group.groupWarning = (groupActivitiesThisWeek.length < 4);
                if(school.groupWarning != true && group.groupWarning == true)
                  school.groupWarning = true;

                group.playedThisWeekUniqueStudents = [];
                groupActivitiesThisWeek.forEach(activity => {
                  if (!group.playedThisWeekUniqueStudents.includes(activity.studentId)) {
                    group.playedThisWeekUniqueStudents.push(activity.studentId);
                  }
                });

                let groupActivitiesThisMonth = school.activities.filter(a => a.groupId == group.id && new Date(a.started.toDate()) >= lastMonth);
                group.playedThisMonth = groupActivitiesThisMonth;
                group.playedThisMonthUniqueStudents = [];
                groupActivitiesThisMonth.forEach(activity => {
                  if (!group.playedThisMonthUniqueStudents.includes(activity.studentId)) {
                    group.playedThisMonthUniqueStudents.push(activity.studentId);
                  }
                });
              });

              //Order groups by playedThisMonth
              school.groups.sort((a, b) => (a.playedThisMonth.length < b.playedThisMonth.length) ? 1 : -1);

              //Create seperate list for stats this week
              school.activitiesThisWeek = school.activities.filter(a => new Date(a.started.toDate()) >= lastWeek);
              school.uniqueStudentsThisWeek = [];
              school.activitiesThisWeek.forEach(activity => {
                if (!school.uniqueStudentsThisWeek.includes(activity.studentId)) {
                  school.uniqueStudentsThisWeek.push(activity.studentId);
                }
              });
          });

          //Done
          this.loadingSchoolStats = false;
        })
        .catch(error => {
            console.error('Error getting documents:', error);
        });
    
    },
    async getMedianResults() {
      try {
          // Get the current date
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

          // Define a Firestore query to get the documents that have a 'started' field with a timestamp between the start and end of today
          const querySnapshot = await db
            .collection('activities')
            .where('schoolId', '==', this.schoolId)
            .where('started', '>=', lastWeek)
            .get();

          let _activitiesPlayedLastDays = [];
          querySnapshot.forEach(doc => {
            let _activity = doc.data();
            _activity.id = doc.id;
            _activitiesPlayedLastDays.push(_activity);
          });

          let days = [];
          let dataMedian = [];
          let dataAverage = [];
          for (let i = 0; i < 7; i++) {
            let lookingForDay = (today.getDate() - (7-i));
            let stringDate = lookingForDay + "-" + today.getMonth();

            console.log(stringDate);
            let scoresOnDay = [];
            for (let j = 0; j < _activitiesPlayedLastDays.length; j++) {
              let activity = _activitiesPlayedLastDays[j];
              let startDate = activity.started.toDate();
              if(startDate.getDate() == lookingForDay)
              {
                  //Get results and add the scores (% based on right/wrong answers)
                  const resultsQuery = await db
                    .collection('activities')
                    .doc(activity.id)
                    .collection('results')
                    .get();

                    resultsQuery.forEach(doc => {
                      let _result = doc.data();
                      let percentage = (_result.rightAnswers / (_result.rightAnswers+_result.wrongAnswers)) * 100;
                      if(typeof percentage != "number")
                      {
                        percentage = 0;
                      }
                      scoresOnDay.push(percentage);
                    });
              }
            }
            let median = this.getMedian(scoresOnDay);
            let average = this.getAverage(scoresOnDay);

            //Skip days where no scores were recorded
            if(scoresOnDay.length> 0){
              days.push(stringDate);
              dataMedian.push(median);
              dataAverage.push(average);
            }
          }

          this.chartData = {
            labels: days,
            datasets: [
              {
                label: 'Mediaan',
                backgroundColor: 'orange',
                data: dataMedian,
                borderColor: 'orange',
              },
              {
                label: 'Gemiddeld',
                backgroundColor: 'blue',
                data: dataAverage,
                borderColor: 'blue',
              }
            ]
          };
          this.showChart = true;
        } 
        catch (error) {
          console.error('Error getting documents:', error);
        }
    },
    getAverage(arr) {
      const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      const average = sum / arr.length;
      return average;
    },
    getMedian(arr) {
      if(arr.length == 0) return 0;

      // Sort the array in ascending order
      const sortedArr = arr.slice().sort((a, b) => a - b);

      const length = sortedArr.length;
      const middleIndex = Math.floor(length / 2);

      // Check if the array length is odd or even
      if (length % 2 === 1) {
        // Array length is odd, return the middle element
        return sortedArr[middleIndex];
      } else {
        // Array length is even, return the average of the two middle elements
        return (sortedArr[middleIndex - 1] + sortedArr[middleIndex]) / 2;
      }
    },
    loadUsersForSchool() {
      db.collection("/users").where("schoolId", "==", this.schoolId).limit(10).get().then((items) => {
        console.log(items);
        let _users = [];
        items.forEach((item) => {
          let _user = item.data();
          _user.id = item.id;

          let dateJs = new Date();
          if (_user.lastLogin) {
            dateJs = _user.lastLogin.toDate();
          }
          if (!_user.avatar) {
            _user.avatar = defaultAvatar;
          }
          let dateString = dateJs.toLocaleString();

          _user.lastLogin = dateString;
          _users.push(_user);
        });

        this.lastLoggedUsers = _users;
      }
      );
    },
    onGotSchoolData(doc) {
      this.school = doc.data();
    },
    getSchools(){
      db.collection("/schools").get().then((items) => {
        let _schools = [];
        items.forEach((item) => {
          let _school = item.data();
          _school.id = item.id;
          _school.activities = [];
          _school.uniqueStudents = [];
          _school.activitiesThisWeek = [];
          _school.uniqueStudentsThisWeek = [];
          _school.groups = [];

          _schools.push(_school);

          //Get school/schoolId/groups
          db.collection("/schools").doc(_school.id).collection("groups").get().then((groups) => {
            let _groups = [];
            groups.forEach((group) => {
              let _group = group.data();
              _group.id = group.id;
              _groups.push(_group);
            });
            _school.groups = _groups;
          });
        });
        this.schools = _schools;

        this.getActivitiesToday();
      });
    },
    getAdminDashboardData() {
        console.log("Admin dashboard");

        //Get last activity stats
        if(this.showMedianResults){
          this.getMedianResults();
        }

        this.getSchools();
    },
  }
};
</script>

<style scoped>
.coinAvatar{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.progressChart {
  min-height: 300px;
}
.activeSchool{
  background-color: blue !important;
  border: 2px solid #30b2d9;
}
.outerTable td{
  padding:0px;
}
.innerTable{
  margin-bottom:0px;
}
.innerTable td{
  padding:1em;
}

.groupRow td{
  padding-left: 2em !important;
  padding:0.5em;
}
</style>