<template>
    <h1>Activiteiten</h1>

    <!--Start modal-->
    <div class="modal fade" id="settingsModal" data-bs-keyboard="true" tabindex="-1" aria-labelledby="modelLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modelLabel" v-if="!specificStudentTarget">
                        Leerdoelen instellen voor de groep</h5>
                    <h5 class="modal-title" id="modelLabel" v-if="specificStudentTarget">Leerdoelen instellen voor {{
                        specificStudentTarget.name }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-floating mb-2">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="inputSessiontimeAddon">
                                        <fa icon="fa-solid fa-clock" /> &nbsp;Minuten speeltijd
                                    </span>
                                    <input type="number" class="form-control" v-model="selectedPlayTime"
                                        id="inputSessiontime">
                                </div>
                            </div>
                            <div class="col-md-6 text-end">
                                <div class="form-check-inline mb-3">
                                    <input class="form-check-input" type="checkbox" v-model="selectedAdaptive" value=""
                                        style="border-radius: 30px; width: 20px; height: 20px;" id="inputCheckbox">
                                    <label class="form-check-label ms-2" style="margin-top:2px" for="inputCheckbox">
                                        Adaptief
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-md-8">
                                <h6>
                                    Leerdoelen <span class="badge rounded-pill bg-primary">{{ this.stagedActiveTemplates.length }} actief</span>
                                </h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <!-- Search filter field-->
                                <div class="input-group mb-3">
                                    <input type="text" class="searchInput form-control" v-model="searchFilter" id="inputSearchFilter" @keyup.enter="applyFilter" placeholder="Leerdoel zoeken">
                                    <button class="btn btn-primary searchButton" type="submit" @click="applyFilter"><fa icon="fa-solid fa-search" /> <fa v-show="searching" icon="fa-solid fa-hourglass" /></button>
                                </div>
                            </div>
                        </div>

                        <ol class="breadcrumb animateIn" v-if="activeDomain">
                            <li class="breadcrumb-item"><a href="#" @click="activeDomain = null">Basisvaardigheden</a></li>
                            <li class="breadcrumb-item" v-if="activeDomain" @click="selectDomain(activeDomain)"><a
                                    href="#">{{ activeDomain.name }}</a></li>
                            <li class="breadcrumb-item" v-if="activeDomain && activeDomain.activeSubdomain"
                                @click="selectSubdomain(activeDomain.activeSubdomain)"><a
                                    href="#">{{ activeDomain.activeSubdomain.name }}</a></li>
                            <li class="breadcrumb-item active"
                                v-if="activeDomain && activeDomain.activeSubdomain && activeDomain.activeSubdomain.activeLine">
                                {{ activeDomain.activeSubdomain.activeLine.name }}</li>
                        </ol>

                        <!--List all domains-->
                        <div class="list-group" v-if="!activeDomain">
                            <button @click="selectDomain(domain)" type="button" class="list-group-item list-group-item-action" v-for="(domain, domainIndex) in domains" :key=domainIndex>
                                <a href="#">{{ domain.name }}</a> <span class="badge rounded-pill bg-info">{{ templatesForDomain(domain) }}</span>
                            </button>
                        </div>

                        <!--List subdomains in selected domain-->
                        <div class="list-group" v-if="activeDomain && activeDomain.subdomains && !activeDomain.activeSubdomain">
                            <button @click="selectSubdomain(subdomain)" type="button" class="list-group-item list-group-item-action"  
                                v-for="(subdomain, subdomainIndex) in activeDomain.subdomains" :key=subdomainIndex
                                v-show="templatesForSubdomain(subdomain) > 0">
                                <a href="#">{{ subdomain.name }} </a> <span class="badge rounded-pill bg-info">{{ templatesForSubdomain(subdomain) }}</span>
                                <div class="form-check goal-check">
                                    <input class="form-check-input" type="checkbox" style="cursor:pointer;" value=""
                                        :id="subdomainIndex + '-subdomain-checked'"
                                        @click="$event.stopPropagation()"
                                        @change="checkEntireSubdomain(subdomain);" v-model="subdomain.hasCheckedGoals">
                                </div>
                            </button>
                        </div>

                        <!--List learning lines in selected subdomain-->
                        <div v-if="activeDomain && activeDomain.activeSubdomain && activeDomain.activeSubdomain.lines && !activeDomain.activeSubdomain.activeLine">    
                            <div class="list-group mb-3" v-for="(line, lineIndex) in activeDomain.activeSubdomain.lines" :key=lineIndex v-show="templatesForLine(line) > 0">
                                    <button type="button" class="list-group-item list-group-item-action" @click="line.hasCheckedGoals=!line.hasCheckedGoals; checkEntireLine(line)">
                                        <b>{{ line.name }}</b>
                                    </button>

                                    <button type="button" :class="{ 'hidden': goalTemplate.hidden }" class="list-group-item list-group-item-action" v-for="(goalTemplate, goalIndex) in templatesInLine(line.id)" :key=goalIndex @click="checkTemplate(goalTemplate)">
                                        <span v-html="highlightText(goalTemplate.name)"></span>
                                        <span v-show="goalTemplate.label && goalTemplate.label.length>0" class="badge bg-warning ms-1">
                                            {{ goalTemplate.label }}
                                        </span>

                                        <div v-show="selectedAdaptive && partOfAdaptiveChain(goalTemplate)" class="connectLine"></div>
                                        <div v-if="selectedAdaptive" class="form-check goal-check">
                                            <input class="form-check-input" type="checkbox"
                                                :checked="isTemplateActive(goalTemplate)" :id="goalIndex + '-goal-checked'"
                                                :class="{ 'adaptiveBox': partOfAdaptiveChain(goalTemplate) }">
                                        </div>
                                        <div v-else class="form-check goal-check">
                                            <input class="form-check-input" type="checkbox"
                                                :checked="isTemplateActive(goalTemplate)" :id="goalIndex + '-goal-checked'">
                                        </div>

                                        <span class="float-end rn">{{ goalTemplate.rn }}</span>
                                        <span class="float-end year">{{ goalTemplate.year }}</span>
                                        <div class="adaptiveFlag"></div>
                                    </button>
                            </div>                          
                        </div>
                        <br>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-default me-1" @click="cancelSettings()"
                        data-bs-dismiss="modal">Annuleren</button>
                    <button type="button" class="btn btn-warning" @click="resetSettings()"
                        v-if="stagedActiveTemplates.length > 0">Reset</button>
                    <button type="button" class="btn btn-warning" @click="resetToSettingsFromGroup()"
                        v-else-if="specificStudentTarget">Reset naar groep</button>
                    <button type="button" class="btn btn-primary" @click="saveSettings()"
                        data-bs-dismiss="modal">Opslaan</button>
                </div>
            </div>
        </div>
    </div>
    <!--End modal-->

    <br>
    <div class="smoothContainer">
        <div class="input-group mb-3">
            <select v-model="selectedGroup" class="text-center form-select form-select-lg" id="groupSessionSelection"
                @change="selectGroupFromList(selectedGroup)">
                <option v-for="(group, groupIndex) in studentGroups" :key="groupIndex" :value="group">{{ group.name }}
                </option>
            </select>
        </div>

        <div class="nav justify-content-end mb-md-3" v-if="this.$route.params.hideMenu != 'hidden'">
            <button class="btn btn-primary me-md-2" type="button" data-bs-toggle="modal" data-bs-target="#settingsModal"
                @click="selectGroupActivities()">
                <fa icon="fa-solid fa-bars-staggered" /> Leerdoelen
            </button>
        </div>

        <table class="table align-middle">
            <thead>
                <tr>
                    <th scope="col">
                        <fa icon="fa-solid fa-user" /> <span class="d-none d-md-inline">Leerling</span>
                    </th>
                    <th scope="col" @click="flipSortOrder()" style="cursor:pointer">
                        <fa icon="fa-solid fa-calendar-days" /> <span class="d-none d-md-inline">Gespeeld</span> 
                        <fa icon="fa-solid fa-arrow-up-1-9" v-if="sortDateDirection" />
                        <fa icon="fa-solid fa-arrow-down-9-1" v-if="!sortDateDirection" />
                    </th>
                    <th scope="col">
                        <fa icon="fa-solid fa-bullseye" /> <span class="d-none d-md-inline">Leerdoelen</span>
                    </th>
                    <th scope="col"></th>
                </tr>
            </thead>

            <tbody class="activityTable">
                <tr v-for="(student, studentIndex) in selectedGroup.students" :key="studentIndex"
                    :class="{ 'playing': student.activity.state == 1 }">
                        <td @click="openStudent(student)"  :id="'session-' + student.activity.id">
                            <a class="fw-bold text-decoration-none">
                                <div class="studentWorldImage" :style="{ backgroundImage: `url(${getImageUrl(student.world)})` }">
                                    <div class="studentLevelBadge">{{ student.level || 1 }}</div> {{ student.name }}
                                </div>
                            </a>
                        </td>
                    <td>
                        <a v-if="student.activity.state == 1" @click="openActivity(student)"
                            class="fw-bold text-decoration-none">
                            <div>
                                <div class="playingBarBackground">
                                    <div class="playingBar"
                                        :style="{ width: 100 - timeRemainingPercentage(selectedGroup.students[0].activity) + '%' }">
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a v-else @click="openActivity(student)" class="fw-bold text-decoration-none">{{
                            formatLastActivity(student) }}</a>
                    </td>
                    <td class="templateNames text-decoration-none" v-if="student.activity.state == 1">
                        <fa icon="fa-solid fa-lock" />
                        {{ getSettingTemplateNames(student.activity.settingTemplateIds).toString() }}
                    </td>
                    <td class="templateNames text-decoration-none" v-else-if="student.settingTemplateIds">
                        <a :class="{ 'text-warning': student.settingTemplateIds.length == 0 }" class="text-decoration-none" data-bs-toggle="modal" data-bs-target="#settingsModal"
                            @click="changeSpecificStudentSettings(student)">
                            <fa icon="fa-solid fa-pen-to-square" />
                            {{ getSettingTemplateNames(student.settingTemplateIds).toString() }}
                        </a>
                    </td>
                    <td class="templateNames text-decoration-none" v-else-if="selectedGroup.settingTemplateIds">
                        <a class="text-decoration-none" data-bs-toggle="modal" data-bs-target="#settingsModal"
                            @click="changeSpecificStudentSettings(student)">
                            <fa icon="fa-solid fa-pen-to-square" />
                            {{ getSettingTemplateNames(selectedGroup.settingTemplateIds).toString() }}
                        </a>
                    </td>
                    <td>
                        <div class="nav mb-mt-3">
                            <button v-if="student.startingActivity" type="button" class="btn btn-lg btn-warning me-md-2">
                                <fa icon="fa-solid fa-play" /> <span class="d-none d-md-inline">Starten...</span>
                            </button>
                            <button
                                v-else-if="student.activity.state != 1 && student.settingTemplateIds && student.settingTemplateIds.length > 0"
                                type="button" class="btn btn-lg btn-success me-md-2"
                                @click="startActivityForStudent(student)">
                                <fa icon="fa-solid fa-play" /> <span class="d-none d-md-inline">Start</span>
                            </button>
                            <button v-else-if="student.activity.state == 1" type="button"
                                class="btn btn-lg btn-warning me-md-2" @click="stopActivity(student.activity)">
                                <fa icon="fa-solid fa-stop" /> <span class="d-none d-md-inline">Stop</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>

        <LoadingSpinner :loading="loadingStudents" />
    </div>
    <a v-if="this.$route.params.hideMenu != 'hidden'" class="d-flex justify-content-center text-decoration-none"
        @click="this.$router.push('/activities/hidden')">
        <div>
            <fa icon="fa-solid fa-child" /> Digibord modus
        </div>
    </a>
</template>

<script>
import { auth, db } from '@/main'
import { firestore } from '@/main'
import { router } from '@/router';
import { shared } from '@/shareddbmethods';
import LoadingSpinner from './Nested/LoadingSpinner.vue';

export default {
    name: "SessionsModule",
    data() {
        return {
            searchFilter: "",
            searching:false,
            studentGroups: [],
            domains: [],
            devices: [],
            settingTemplates: [],
            activeTemplates: [],
            stagedActiveTemplates: [],
            groupActivities: [],
            activeDomain: null,
            specificStudentTarget: null,
            intervalId: "",
            currentUnixTime: 0,
            loadingStudents: true,
            sortDateDirection: true,
            selectedGroup: "",
            selectedDevice: null,
            selectedTemplate: "",
            selectedPlayTime: 10,
            selectedAdaptive: false,
            activitySettingsOpen: false,
            session: null,
            group: null,
        };
    },
    beforeRouteLeave(to, from, next) {
        this.stopInterval();
        next();
    },
    computed: {
        templatesInSubdomain: function()
        {
            let activeSubdomainId = this.activeDomain.activeSubdomain.id;
            let result = this.settingTemplates.filter(template => (template.subdomainId === activeSubdomainId));
            result.sort((a, b) => a.order - b.order); // sort by order property
            return result;
        },
        
    },
    methods: {
        getImageUrl(world) {
            if (!world) {
                return require(`@/assets/images/worlds/Default.png`);
            }
            return require(`@/assets/images/worlds/${world}.png`);
        },
        templatesInLine: function (lineId) {
            let result = this.settingTemplates.filter(template => (template.lineId === lineId));
            result.sort((a, b) => a.order - b.order); // sort by order property
            return result;
        },
        highlightText(text) {
            if (this.searchFilter.length == 0) {
                return text;
            }
            let regex = new RegExp(this.searchFilter, 'gi');
            return text.replace(regex, match => `<span class="searchHighlight">${match}</span>`);
        },
        async applyFilter() {
            // Abort previous async tasks
            if (this.abortController) {
                this.abortController.abort();
            }
            this.abortController = new AbortController();

            this.searching = true;

            //Start fresh search
            this.searchFilter = this.searchFilter.toLowerCase();
            //Find this text in settingTemplates and return the best mathing result
            let bestMatch = this.settingTemplates.find(template => (template.name.toLowerCase().includes(this.searchFilter)));
            if(!bestMatch) {
                this.searching = false;
                return;
            }
            console.log(bestMatch.name);

            //Get domain using id
            let domain = this.domains.find(domain => domain.id == bestMatch.domainId);
            if (!domain) {
                this.searching = false;
                return;
            }

            //Select domain if not already selected
            if (this.activeDomain != domain) {
                await this.selectDomain(domain, { signal: this.abortController.signal });
            }
            let subdomain = domain.subdomains.find(subdomain => subdomain.id === bestMatch.subdomainId);
            if(this.activeDomain.activeSubdomain != subdomain)
            {
                await this.selectSubdomain(subdomain, { signal: this.abortController.signal });
            }
            
            this.searching = false;
        },
        isTemplateActive(template) {
            return this.stagedActiveTemplates.includes(template);
        },
        partOfAdaptiveChain(goalTemplate) {
            //If template is checked itself, its not part of a chain but a main active goal
            if (this.isTemplateActive(goalTemplate)) {
                return false;
            }

            const isPartOfChain = this.settingTemplates.some(template => {
                return this.isTemplateActive(template) && template.lineId === goalTemplate.lineId && template.order <= goalTemplate.order;
            });
            return isPartOfChain;
        },
        templatesForLine(line) {
            const count = this.settingTemplates.reduce((accumulator, template) => {
                if (template.lineId === line.id) {
                    return accumulator + 1;
                }
                return accumulator;
            }, 0);
            return count;
        },
        templatesForSubdomain(subdomain) {
            const count = this.settingTemplates.reduce((accumulator, template) => {
                if (template.subdomainId === subdomain.id) {
                    return accumulator + 1;
                }
                return accumulator;
            }, 0);
            return count;
        },
        templatesForDomain(domain) {
            const count = this.settingTemplates.reduce((accumulator, template) => {
                if (template.domainId === domain.id) {
                    return accumulator + 1;
                }
                return accumulator;
            }, 0);
            return count;
        },
        checkEntireSubdomain(subdomain) {
            console.log("Check entire subdomain");
            if (subdomain.hasCheckedGoals) {
                // If subdomain has checked goals, add the matching templates to activeTemplates
                this.settingTemplates.forEach((template) => {
                    if (template.subdomainId == subdomain.id && !this.stagedActiveTemplates.includes(template)) {
                        this.stagedActiveTemplates.push(template);
                    }
                });
            }
            else {
                // If subdomain doesn't have checked goals, remove matching templates from activeTemplates
                this.stagedActiveTemplates = this.stagedActiveTemplates.filter(activeTemplate => (activeTemplate.subdomainId != subdomain.id));
            }
        },
        checkEntireLine(line) {
            console.log("Check entire line");
            if (line.hasCheckedGoals) {
                // If line has checked goals, add the matching templates to activeTemplates
                this.settingTemplates.forEach((template) => {
                    if (template.lineId == line.id && !this.stagedActiveTemplates.includes(template)) {
                        this.stagedActiveTemplates.push(template);
                    }
                });
            }
            else {
                // If line doesn't have checked goals, remove matching templates from activeTemplates
                this.stagedActiveTemplates = this.stagedActiveTemplates.filter(activeTemplate => (activeTemplate.lineId != line.id));
            }
        },
        checkTemplate(template) {
            let templateActive = this.stagedActiveTemplates.includes(template);
            if (templateActive) {
                // If the template is already in activeTemplates, remove it
                const indexToRemove = this.stagedActiveTemplates.indexOf(template);
                if (indexToRemove !== -1) {
                    this.stagedActiveTemplates.splice(indexToRemove, 1);
                }
            }
            else if (!templateActive) {
                // If the template is not in activeTemplates, add it
                this.stagedActiveTemplates.push(template);
            }
        },
        getDomains() {
            db.collection("/domains").get().then((items) => {
                console.log(items);
                let _domains = [];
                items.forEach((item) => {
                    let _domain = item.data();

                    //If showBsoFeatures is false, skip BSO domains (forBso == true) and same for showSchoolFeatures
                    if ((this.$store.state.showBsoFeatures && _domain.forBso) 
                    || (this.$store.state.showSchoolFeatures && _domain.forSchool)) {
                        _domain.id = item.id;
                        _domains.push(_domain);
                    }            
                });

                //Order by order field
                _domains.sort((a, b) => a.order - b.order);

                this.domains = _domains;
            });
        },
        async selectDomain(domain) {
            this.activeDomain = domain;
            if (this.activeDomain.activeSubdomain) {
                if (this.activeDomain.activeSubdomain.activeLine) {
                    this.activeDomain.activeSubdomain.activeLine = null;
                }
                this.activeDomain.activeSubdomain = null;
            }
            this.activeDomain.subdomains = [];
            await db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').orderBy('name', 'asc').get().then((items) => {
                console.log(items);
                let _subdomains = [];
                items.forEach((item) => {
                    let _subdomain = item.data();
                    _subdomain.id = item.id;
                    _subdomains.push(_subdomain);
                });

                //Order by order field
                _subdomains.sort((a, b) => a.order - b.order);

                this.activeDomain.subdomains = _subdomains;
                //Make sure to check the subdomain if an active child goal template has it as a parent
                this.activeDomain.subdomains.forEach((subdomain) => {
                    subdomain.hasCheckedGoals = (this.stagedActiveTemplates.some((template) => (template.subdomainId == subdomain.id)));
                });
            });
        },
        async selectSubdomain(subdomain) {
            this.activeDomain.activeSubdomain = subdomain;
            if (this.activeDomain.activeSubdomain.activeLine) {
                this.activeDomain.activeSubdomain.activeLine = null;
            }
            this.activeDomain.activeSubdomain.lines = [];
            await db.collection("/domains").doc(this.activeDomain.id).collection('subdomains').doc(this.activeDomain.activeSubdomain.id).collection('lines').orderBy('order', 'asc').get().then((items) => {
                console.log(items);
                let _lines = [];
                items.forEach((item) => {
                    let _line = item.data();
                    _line.id = item.id;
                    _lines.push(_line);
                });

                //Order lines by order field
                _lines.sort((a, b) => a.order - b.order);

                this.activeDomain.activeSubdomain.lines = _lines;
                //Make sure to check the line if an active child goal template has it as a parent
                this.activeDomain.activeSubdomain.lines.forEach((line) => {
                    line.hasCheckedGoals = (this.stagedActiveTemplates.some((template) => (template.lineId == line.id)));
                });
            });
        },
        activateTemplate(template, activate) {
            if (activate && !this.stagedActiveTemplates.find(obj => obj == template)) {
                this.stagedActiveTemplates.push(template);
            }
            else {
                this.stagedActiveTemplates = this.stagedActiveTemplates.filter(obj => obj !== template);
            }
        },
        getSettingTemplateNames(settingTemplatesIds) {
            if (settingTemplatesIds.length == 0) {
                return "Geen leerdoelen";
            }
            const filteredTemplates = this.settingTemplates.filter(template => settingTemplatesIds.includes(template.id));
            const templateNames = filteredTemplates.map(template => template.name);
            return templateNames;
        },
        changeSpecificStudentSettings(student) {
            if (this.$route.params.hideMenu != 'hidden') {
                this.resetAllSettingTemplatesChecks();
                this.specificStudentTarget = student;
                this.selectedAdaptive = this.specificStudentTarget.adaptive || this.selectedAdaptive;
                this.selectedPlayTime = this.specificStudentTarget.selectedPlayTime || 10;
                //If this student has its open setting template ids; use those
                if (this.specificStudentTarget.settingTemplateIds) {
                    this.stagedActiveTemplates = this.settingTemplates.filter(template => this.specificStudentTarget.settingTemplateIds.includes(template.id));
                }
            }
        },
        selectGroupActivities() {
            this.specificStudentTarget = null;
            this.activeDomain = null;
            this.selectedAdaptive = this.selectedGroup.adaptive || false;
            this.selectedPlayTime = this.selectedGroup.selectedPlayTime || 10;
            this.activateSettingTemplates(this.selectedGroup);
        },
        activateSettingTemplates(targetGroup) {
            this.resetAllSettingTemplatesChecks();

            this.stagedActiveTemplates = this.settingTemplates.filter(template => targetGroup.settingTemplateIds.includes(template.id));
        },
        resetAllSettingTemplatesChecks() {
            this.activeTemplates = [];
        },
        removeListeners()
        {
            if (this.studentListenerUnsubscribe != null) {
                this.studentListenerUnsubscribe();
                this.studentListenerUnsubscribe = null;
            }
            if (this.activityListenerUnsubscribe != null) {
                this.activityListenerUnsubscribe();
                this.activityListenerUnsubscribe = null;
            }
        },
        loadStudentsAndActivies(targetGroup) {
            console.log("Load students and their activities for group:" + targetGroup.id);
            targetGroup.students = [];
            this.loadingStudents = true;

            //Make sure group has activities
            if (!targetGroup.settingTemplateIds) {
                targetGroup.settingTemplateIds = [];
            }
            this.activateSettingTemplates(targetGroup);

            this.removeListeners();

            //Might want to limit this as the number of students can be quite large
            this.studentListenerUnsubscribe = db.collection("/schools").doc(this.$store.state.schoolId).collection("students")
                .where('groupId', '==', targetGroup.id).onSnapshot((studentDocs) => {
                let _students = [];
                studentDocs.forEach((studentDoc) => {
                    let data = studentDoc.data();
                    data.id = studentDoc.id;
                    _students.push(data);
                });
                targetGroup.students = _students;
                this.combineStudentsWithActivities(targetGroup);
            });


            //Might want to limit this as the number of activities can be quite large
            this.activityListenerUnsubscribe = db.collection("/activities")
                .where('schoolId', '==', this.$store.state.schoolId)
                .where('groupId', '==', targetGroup.id).onSnapshot((activityDocs) => {
                this.loadingStudents = false;
                let _activities = [];
                activityDocs.forEach((activityDoc) => {
                    let data = activityDoc.data();
                    data.id = activityDoc.id;
                    _activities.push(data);
                });
                this.groupActivities = _activities;
                this.combineStudentsWithActivities(targetGroup);
            });
        },
        combineStudentsWithActivities(targetGroup) {
            //Assign the activities to the students
            for (let i = 0; i < targetGroup.students.length; i++) {
                const student = targetGroup.students[i];
                let studentHasActivity = false;
                let activityIsPlaying = false;
                for (let j = 0; j < this.groupActivities.length; j++) {
                    let activity = this.groupActivities[j];
                    if (student.activityId == activity.id) {
                        if (activity.state == 1) 
                        {
                            activityIsPlaying = true;
                        }
                        student.activity = activity;
                        student.lastActivity = activity.started;
                        studentHasActivity = true;
                    }
                }
                if (!studentHasActivity && !activityIsPlaying) {
                    if (student.settingTemplateIds != null && student.settingTemplateIds.length > 0) {
                        console.log("Student has specific settings");
                        student.activity = {
                            state: 0,
                            settingTemplateIds: student.settingTemplateIds
                        };
                    }
                    else {
                        console.log("Student has no specific settings. Using group settings.");
                        console.log(targetGroup.settingTemplateIds);
                        student.activity = {
                            state: 0,
                            settingTemplateIds: targetGroup.settingTemplateIds
                        };
                    }
                }
            }
            this.sortByLastActivity();
        },
        async startActivityForStudent(student) {
            //Assign to selected device (default to 0)
            if (this.devices.length > 0) {
                student.startingActivity = true;
                this.selectedDevice = this.devices[0];
                //Get the first device assigned to group
                this.devices.forEach((device) => {
                    if (device.groupId == this.selectedGroup.id) {
                        this.selectedDevice = device;
                    }
                });
                //Create new activity session
                let _settingTemplateIds = this.selectedGroup.settingTemplateIds;
                let _adaptiveSettingTemplateIds = this.selectedGroup.adaptiveSettingTemplateIds || [];

                if (student.settingTemplateIds != null && student.settingTemplateIds.length > 0) {
                    console.log("Starting activity with student specific settings");
                    _settingTemplateIds = student.settingTemplateIds;
                    _adaptiveSettingTemplateIds = student.adaptiveSettingTemplateIds || [];
                }
                else {
                    alert("Selecteer minimaal 1 leerdoel.");
                }

                //Make sure to stop all sessions of this student that are not done (state==2)
                await shared.stopActivitiesOnDevice(this.selectedDevice.id);
                //Make sure all previous activities have been attributed where state==2
                await shared.attributeActivities(student.id, this.$store.state.schoolId);

                const token = shared.generateToken();
                const timestamp = firestore.FieldValue.serverTimestamp();
                const unixTimestamp = Math.floor(Date.now() / 1000);
                try {
                    // Create a new batch
                    const batch = db.batch();
                    // Generate a new document reference for the activity document
                    const activityRef = db.collection("activities").doc();
                    // Define the data for the activity document
                    const activityData = {
                        state: 1,
                        deviceName: this.selectedDevice.name,
                        deviceId: this.selectedDevice.id,
                        groupId: this.selectedGroup.id,
                        schoolId: this.$store.state.schoolId,
                        studentId: student.id,
                        studentName: student.name,
                        maxHeight: student.height || 0,
                        level: student.level || 1,
                        crates: student.crates || 0,
                        experience: student.experience || 0,
                        currency: student.currency || 0,
                        unlocks: student.unlocks || [],
                        achievements: student.achievements || {},
                        activated: student.activated || [],
                        startUnix: unixTimestamp,
                        started: timestamp,
                        settingTemplateIds: _settingTemplateIds,
                        adaptiveSettingTemplateIds: _adaptiveSettingTemplateIds,
                        playtime: this.selectedPlayTime * 60,
                        token: token,
                        attributed: false
                    };
                    // Add the write operation for the activity document to the batch
                    batch.set(activityRef, activityData);

                    // Make sure to allow writing to template id's by setting the write token
                    let allSettingTemplateIds = _settingTemplateIds.concat(_adaptiveSettingTemplateIds);                    
                    for (let i = 0; i < allSettingTemplateIds.length; i++) {
                        const templateId = allSettingTemplateIds[i];
                        const templateRef = db.collection("schools").doc(this.$store.state.schoolId).collection("students").doc(student.id).collection("save").doc(templateId);
                        // Add the write operation for the template save document to the batch
                        batch.set(templateRef, { token: token }, { merge: true });
                    }

                    // Update the student with activity ID and last activity
                    const studentRef = db.collection("schools").doc(this.$store.state.schoolId).collection("students").doc(student.id);
                    console.log("Started activity " + activityRef.id);

                    // Update the student with the activity id and token so we can write to schoolId/students/studentId using only token
                    batch.update(studentRef, {
                        activityId: activityRef.id,
                        lastActivity: timestamp,
                        token: token
                    });
                    student.activityId = activityRef.id;

                    // Update the device with message and assigned session
                    const deviceRef = db.collection("devices").doc(this.selectedDevice.id);

                    // Add the update operation for the device document to the batch
                    batch.update(deviceRef, {
                        activityId: activityRef.id,
                        chat: "Zet hem op!"
                    });
                    // Commit the batch
                    await batch.commit();
                    console.log("Activity created and state set to 1; playing!");
                }
                catch (error) {
                    console.error("Could not batch start activity:", error);
                }
                student.startingActivity = false;
            }
            else {
                alert("Koppel eerst en VR headset aan deze groep.");
                return;
            }
        },
        openStudent(student) {
            if (this.$route.params.hideMenu != 'hidden') {
                router.push('/student/' + student.id);
            }
        },
        openActivity(student) {
            if (!student.lastActivity) {
                return;
            }
            if (this.$route.params.hideMenu != 'hidden') {
                router.push('/activity/' + student.id + '/' + student.name + '/' + student.activityId);
            }
        },
        tickTimeOnSessions() {
            this.currentUnixTime = Math.floor(Date.now() / 1000);
        },
        timeRemainingPercentage(activity) {
            const started = activity.startUnix;
            const totalSessionSeconds = activity.playtime;
            const seconds = totalSessionSeconds - (this.currentUnixTime - started);

            const percentageRemaining = (seconds / totalSessionSeconds) * 100;

            //Stop activity with a 2 second delay to give the VR app time to stop, and 0:00 to be displayed
            if (seconds < 0 && activity.state != 2) {
                if (seconds < -2) {
                    this.stopActivity(activity);
                }
                return 'Klaar!';
            }

            return `${percentageRemaining}`;
        },
        timeRemainingMinutes(activity) {
            const started = activity.startUnix;
            const totalSessionSeconds = activity.playtime;
            const seconds = totalSessionSeconds - (this.currentUnixTime - started);
            const minutes = Math.max(0, Math.floor(seconds / 60));
            const remainingSeconds = Math.max(0, (seconds % 60));

            return `${minutes}:${String(remainingSeconds).padStart(2, "0")}`;
        },
        stopInterval() {
            clearInterval(this.intervalId);
        },
        stopActivity(activity) {
            db.collection("/activities").doc(activity.id).update({
                state: 2
            }).then(() => {
                console.log("Session state set to 2; ended.");
            }).catch((error) => {
                console.error("Could not set chat: ", error);
            });
        },
        selectGroupFromList(group) {
            this.$store.state.groupId = group.id;
            this.selectedAdaptive = group.adaptive || false;
            this.selectedPlayTime = group.selectedPlayTime || 10;
            this.loadStudentsAndActivies(group);
        },
        onLoadedGroups(items) {
            let _studentGroups = [];
            items.forEach((item) => {
                let id = item.id;
                let data = item.data();
                data.id = id;
                _studentGroups.push(data);
            });
            this.studentGroups = _studentGroups;
            //Start with first group or once previously selected stored in the store
            if (this.studentGroups.length > 0) {
                this.selectedGroup = this.studentGroups[0];
                this.studentGroups.forEach((group) => {
                    if (this.$store.state.groupId == group.id) {
                        this.selectedGroup = group;
                    }
                });
                this.selectedGroup.activities = [];
                this.selectGroupFromList(this.selectedGroup);
            }
            //Load devices tied to this group
            db.collection("/devices").where('schoolId', '==', this.$store.state.schoolId).get().then(this.onLoadedDevices);
        },
        cancelSettings() {
            this.activitySettingsOpen = false;
        },
        resetSettings() {
            this.stagedActiveTemplates = this.activeTemplates;
            this.activeDomain = null;
        },
        resetToSettingsFromGroup() {
            this.resetSettings();
            //Resets specific student to group settings
            if (this.specificStudentTarget && this.selectedGroup) {
                this.selectedAdaptive = this.selectedGroup.adaptive || false;
                this.specificStudentTarget.adaptive = this.selectedGroup.adaptive;

                this.specificStudentTarget.settingTemplateIds = this.selectedGroup.settingTemplateIds;
                this.stagedActiveTemplates = this.settingTemplates.filter(template => this.specificStudentTarget.settingTemplateIds.includes(template.id));
            }
        },
        saveSettings() {
            //Apply staged active templates
            this.activeTemplates = this.stagedActiveTemplates;

            //Reset depth
            this.activeDomain = null;

            //Create a flat array with all selected settingTemplate ids
            let _settingTemplateIds = [];
            for (let i = 0; i < this.activeTemplates.length; i++) {
                const template = this.activeTemplates[i];
                _settingTemplateIds.push(template.id);
            }

            //Create list of adaptive settingTemplate ids (will activate ingame after completing one)
            let _adaptiveSettingTemplateIds = [];
            if (this.selectedAdaptive) {
                for (let i = 0; i < this.settingTemplates.length; i++) {
                    const template = this.settingTemplates[i];
                    if (this.partOfAdaptiveChain(template)) {
                        _adaptiveSettingTemplateIds.push(template.id);
                    }
                }
            }

            //If we selected a specific student, assign settings to student
            if (this.specificStudentTarget != null) {
                this.specificStudentTarget.adaptive = this.selectedAdaptive;
                this.specificStudentTarget.selectedPlayTime = this.selectedPlayTime;
                this.specificStudentTarget.settingTemplateIds = _settingTemplateIds;
                this.specificStudentTarget.adaptiveSettingTemplateIds = _adaptiveSettingTemplateIds

                console.log(this.specificStudentTarget.adaptive);

                db.collection("/schools").doc(this.$store.state.schoolId).collection("students").doc(this.specificStudentTarget.id).set({
                    settingTemplateIds: _settingTemplateIds,
                    adaptiveSettingTemplateIds: _adaptiveSettingTemplateIds,
                    changedById: auth.currentUser.uid,
                    adaptive: this.specificStudentTarget.adaptive,
                    selectedPlayTime: this.specificStudentTarget.selectedPlayTime
                }, { merge: true });
            }

            //Else assign it to the group and all students
            else if (this.selectedGroup != null) {
                const adaptiveGroup = this.selectedAdaptive;
                const playTimeGroup = this.selectedPlayTime;

                this.selectedGroup.adaptive = adaptiveGroup;
                this.selectedGroup.settingTemplateIds = _settingTemplateIds;
                this.selectedGroup.selectedPlayTime = playTimeGroup;
                this.selectedGroup.adaptiveSettingTemplateIds = _adaptiveSettingTemplateIds;

                db.collection("/schools").doc(this.$store.state.schoolId).collection("groups").doc(this.selectedGroup.id).update({
                    settingTemplateIds: _settingTemplateIds,
                    adaptiveSettingTemplateIds: _adaptiveSettingTemplateIds,
                    changedById: auth.currentUser.uid,
                    adaptive: adaptiveGroup,
                    selectedPlayTime: playTimeGroup
                });

                //Update all students with this group id
                db.collection("/schools").doc(this.$store.state.schoolId).collection("students").where("groupId", "==", this.selectedGroup.id).get().then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        doc.ref.update({
                            settingTemplateIds: _settingTemplateIds,
                            adaptiveSettingTemplateIds: _adaptiveSettingTemplateIds,
                            changedById: auth.currentUser.uid,
                            adaptive: adaptiveGroup,
                            selectedPlayTime: playTimeGroup
                        });
                    });
                });

                //And apply currently loaded students (so changes are visible immediately)
                this.selectedGroup.students.forEach((student) => {
                    student.settingTemplateIds = _settingTemplateIds,
                    student.adaptiveSettingTemplateIds = _adaptiveSettingTemplateIds,
                    student.adaptive = adaptiveGroup,
                    student.selectedPlayTime = playTimeGroup
                });
                //make sure to save activities to students too
                this.combineStudentsWithActivities(this.selectedGroup);
            }
        },
        flipSortOrder() {
            this.sortDateDirection = !this.sortDateDirection;
            this.sortByLastActivity();
        },
        sortByLastActivity() {
            this.selectedGroup.students = this.selectedGroup.students.sort((a, b) => {
                let aLastActivity = a.lastActivity || new Date("1900-01-02");
                let bLastActivity = b.lastActivity || new Date("1900-01-02");
                const aIsPlaying = (a.activity && a.activity.state == 1);
                const bIsPlaying = (b.activity && b.activity.state == 1);
                if (aIsPlaying) {
                    aLastActivity = new Date("1900-01-01");
                }
                if (bIsPlaying) {
                    bLastActivity = new Date("1900-01-01");
                }
                if (this.sortDateDirection) {
                    return aLastActivity - bLastActivity;
                }
                else {
                    return bLastActivity - aLastActivity;
                }
            });
        },
        formatLastActivity(student) {
            if (!student.lastActivity) {
                return "Nog niet gespeeld";
            }
            let date = student.lastActivity.toDate();
            let today = new Date();
            let isToday = date.getDate() == today.getDate() &&
                date.getMonth() == today.getMonth() &&
                date.getFullYear() == today.getFullYear();
            if (isToday) {
                let time = date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });
                return "Vandaag om " + time + "";
            }
            let formatted = date.toLocaleDateString('nl-NL', { day: '2-digit', month: 'numeric' });
            return formatted;
        },
        addNewSession() {
            let groupName = prompt("Wat is de groep naam?");
            let timestamp = firestore.FieldValue.serverTimestamp();
            if (groupName != null) {
                let newGroup = {
                    name: groupName,
                    number: 0,
                    created: timestamp
                };
                let newDoc = db.collection("/schools/" + this.$store.state.schoolId + "/groups").doc();
                newDoc.set(newGroup);
                console.log("Added new group with auto ID" + newDoc.id);
            }
        },
        onLoadedSettingTemplates(items) {
            let _settingTemplates = [];
            items.forEach((item) => {
                let data = item.data();
                data.id = item.id;
                data.rn = data.rn.toUpperCase();
                if (this.$store.state.user.admin) {
                    _settingTemplates.push(data);
                }
                else if (!data.hidden) {
                    _settingTemplates.push(data);
                }
            });
            this.settingTemplates = _settingTemplates;
            db.collection("/schools/" + this.$store.state.schoolId + "/groups").orderBy("name", "desc").get().then(this.onLoadedGroups);
            this.getDomains();
        },
        onLoadedDevices(items) {
            let _devices = [];
            items.forEach((item) => {
                let data = item.data();
                data.id = item.id;
                _devices.push(data);
            });
            this.devices = _devices;
        },
        removeExtraBackdrop() {
            document.querySelectorAll('.modal-backdrop').forEach((backdrop, index) => {
                if (index > 0) {
                    backdrop.remove();
                }
            });
        }
    },
    async mounted() {
      await shared.getSchool(this.$store.state.schoolId).then((school) => {
        this.$store.state.showBsoFeatures = school.isBso;
        this.$store.state.showSchoolFeatures = school.isSchool;
      });

      db.collection("/settingTemplates").orderBy("name", "desc").get().then(this.onLoadedSettingTemplates);
        this.intervalId = setInterval(() => this.tickTimeOnSessions(), 1000);
        this.currentUnixTime = Math.floor(Date.now() / 1000);

        //Detect if new divs are added with .modal-backdrop and remove them
        window.addEventListener('DOMNodeInserted', this.removeExtraBackdrop);
    },
    beforeUnmount() {
        window.removeEventListener('DOMNodeInserted', this.removeExtraBackdrop);
        this.removeListeners();
    },
    components: { LoadingSpinner }
}
</script>

<style>
.studentLevelBadge{
    background-image: url('@/assets/images/UserLevelBadge.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
    float: inline-start;
    position: relative;
    left: -5px;
    top: -5px;
    color: #d8ebff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-right: 6px;
}
.studentWorldImage{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    text-shadow: 3px 1px 3px rgba(66, 68, 90, 1);
    color:white;
    padding: 15px;
    background-size: cover;
    border-radius: 12px;
}
.hidden{
    color: #c5c5c5;
}
.adaptiveBox {
    background-color: #3459e6a6;
    border-radius: 30px !important;
}
.connectLine {
    pointer-events: none;
    background-color: #7b93ef;
    height: 100%;
    width: 2px;
    position: absolute;
    right: 37px;
    top: -20px;
}

.activityTable {
    font-size: 16px;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
}

.templateNames {
    max-width: 75ch;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.playing {
    font-weight: bold;
    background-color: #e5f9ff;
    /*animation-name: color;
  animation-duration: 1s;
  animation-iteration-count: infinite;*/
}

.playingBarBackground {
    border-radius: 12px;
    height: 22px;
    overflow: hidden;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
}

.playingBar {
    height: 100%;
    background-color: #55a2f0;
}

.playing td {
    background-color: transparent;
}

.goal-check {
    float: right;
    margin-left: 5px;
}

.goal-check .form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid grey;
}

.searchHighlight{
    padding: 5px;
    font-weight: bold;
    color: white;
    background-color: orange;
}
.searchInput{
    border-radius: 30px;
}
.btn.searchButton{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
/* Define the initial state of the backdrop */
.modal-backdrop {
    backdrop-filter: blur(0px);
    background-color: rgba(255, 255, 255, 0.0) !important;
    opacity: 1 !important;
    transition: backdrop-filter 0.3s ease-in-out, background-color 0.3s ease-in-out; 
}

/* Define the final blurred state of the backdrop */
.modal-backdrop.show {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.20) !important;
}

.animation-fade {
    animation-name: fade !important;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes color {
    0% {
        background-color: #fff5e4
    }

    50% {
        background-color: #ffe8c1;
    }

    100% {
        background-color: #fff5e4
    }
}</style>
