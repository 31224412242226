<template>
  <h1>Spellen </h1>

  <div v-if="this.$store.state.user.admin" class="admin smoothContainer mb-3">
    <h5>Instelling template</h5>

    <form autocomplete="off" @submit.prevent="saveSettingTemplate">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-floating mb-2">
            <input class="form-control" @keydown.enter.prevent type="text" v-model="newGoalsSettingName" id="new-GoalsSettingName"
              placeholder="Naam" required>
            <label for="new-GoalsSettingName">Naam</label>
          </div>

          <div class="form-floating mb-2">
            <input class="form-control" @keydown.enter.prevent type="text" v-model="newGoalsSettingLabel" id="new-GoalsSettingLabel"
              placeholder="Optioneel label">
            <label for="new-GoalsSettingName">Optioneel label</label>
          </div>
    
          <div class="mb-2">
            <textarea class="form-control" @keydown.enter.prevent rows="3" v-model="newGoalsSettingDescription" id="new-GoalsSettingDesciption"
              placeholder="Omschrijving">
            </textarea>
          </div>

          <div class="input-group mb-2">
            <div class="form-floating">
              <select class="form-select" v-model="newGoalGameUnlockMode" id="newGoalGameUnlockMode">
                <option value="0" selected>Zelf spel kiezen</option>
                <option value="1">Zelf spel kiezen, maar wel alle spellen uitspelen</option>
                <option value="2">Op volgorde uitspelen</option>
              </select>
              <label for="newGoalGameUnlockMode">Modus</label>
            </div>
          </div>

          <div class="input-group mb-3">
            <select v-model="selectedGame" class="form-select" id="groupSessionSelection"
              @change="addGame(selectedGame)">
              <option value="" selected disabled>+ Voeg een spel toe</option>
              <option v-for="(game, gameIndex) in games" :key="gameIndex" :value="game">{{ game.name }}</option>
            </select>
          </div>

        </div>
        <div class="col-lg-6">
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="" id="visibleSetting" v-model="goalVisible">
            <label class="form-check-label" for="visibleSetting">
              Instelling zichtbaar in portaal
            </label>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="" id="lockedSetting" v-model="goalAlwaysLocked">
            <label class="form-check-label" for="lockedSetting">
              Instelling vergrendeld
            </label>
          </div>

          <input class="d-inline rn year form-control" type="text" v-model="newGoalsSettingYear" id="goalInput" placeholder=" ">
          <input class="d-inline rn form-control mb-2" type="text" v-model="newGoalsSettingRn" id="goalInput" placeholder=" ">


          <div v-if="this.newGoalsSettingLineId.length > 0">
            <div class="form-floating mb-3">
              Leerlijn: <b>{{ getLineById.name }}</b>
              <a class="mb-2 text-decoration-none text-danger ms-1" @click="disconnectLine()">
                <fa icon="fa-solid fa-remove" />
              </a>
            </div>
          </div>
          <div v-else class="form-floating mb-2">
            <input class="form-control" type="text" v-model="searchLineInput" id="goalInput" placeholder="Leerlijn koppelen" @click="searchLineByInput()" @input="searchLineByInput()">
            <label for="goalInput">Leerlijn koppelen</label>
            <div class="dropdown-menu show" v-if="filteredLines.length > 0">
              <div @click="connectToLine(line)" class="dropdown-item" v-for="(line, lineIndex) in filteredLines" :key="lineIndex">
                {{ line.name }}
              </div>
              <div @click="disconnectLine()" class="dropdown-item">
                -
              </div>
            </div>
          </div>

          <TagInput />
        </div>

        <div class="nav justify-content-end mb-md-3">
          <button v-if="editTemplateId.length != 0" class="btn btn-default me-2"
            @click="editTemplateId = ''">Annuleren</button>
          <button v-if="editTemplateId.length != 0" class="btn btn-primary" type="submit"> Instelling bijwerken
            <fa icon="fa-solid fa-hourglass-half" v-if="savingSettings" />
          </button>
          <button v-else class="btn btn-primary ms-2" type="submit">Als nieuwe instelling toevoegen
            <fa icon="fa-solid fa-hourglass-half" v-if="savingSettings" />
          </button>
        </div>
      </div>

      <!--Games added-->
      <div class="smoothContainer animateIn" v-for="(game, gameIndex) in selectedGames" :key="gameIndex">
        <h6 :class="{'mb-0':!game.opened}">
          <a @click="game.opened = !game.opened">
            <img style="max-width: 30px;" :alt="game.id" :src="require(`@/assets/images/gamecards/${game.id}.png`)" />
            {{ game.name }}
            <span class="badge bg-primary">{{ game.levels.length }} levels </span>
          </a>
          <!--Collapse caret-->
          <a href="#" role="button" class="text-decoration-none me-2 ms-2 float-end h5"
            @click="game.opened = !game.opened">
            <fa icon="fa fa-angle-up" v-if="game.opened" />
            <fa icon="fa fa-angle-down" v-else />
          </a>

          <!--Arrow buttons to move game up or down in selectedGames array-->
          <a v-if="gameIndex > 0" href="#" role="button" class="text-decoration-none me-2 ms-2 float-end"
            @click="selectedGames.splice(gameIndex - 1, 0, selectedGames.splice(gameIndex, 1)[0])">
            <fa icon="fa-solid fa-arrow-up" />
          </a>
          <a v-if="gameIndex < selectedGames.length - 1" href="#" role="button"
            class="text-decoration-none me-2 ms-2 float-end"
            @click="selectedGames.splice(gameIndex + 1, 0, selectedGames.splice(gameIndex, 1)[0])">
            <fa icon="fa-solid fa-arrow-down" />
          </a>
        </h6>

        <div class="gameLevelSettings" v-if="game.opened">
          <a class="mb-2 text-decoration-none me-4" @click="addLevel(game)">+ Voeg level toe</a>
          <a class="mb-2 text-decoration-none me-4" @click="copyLevelsFromGame(game)">
            <fa icon="fa-solid fa-copy" /> Kopieer levels
          </a>
          <a v-if="levelsAsJSONCopy != ''" class="mb-2 text-decoration-none me-4" href="#" @click="pasteLevels(game)">
            <fa icon="fa-solid fa-paste" /> Plak levels
          </a>
          <a class="mb-2 text-decoration-none text-warning me-4" @click="removeGame(game)">
            <fa icon="fa-solid fa-remove" /> Game verwijderen
          </a>

          <div class="animateIn smoothContainer mt-2" v-for="(level, levelIndex) in game.levels" :key="levelIndex">
            <h6>Level: {{ (levelIndex + 1) }} {{ level.description }} <a href="#" class="text-danger"
                @click="removeLevel(level, game)">
                <fa icon="fa-solid fa-remove" />
              </a> </h6>

            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="form-floating mt-2 mb-2">
                  <input class="form-control" type="text" v-model="level.description" id="levelDescription"
                    placeholder="Omschrijving">
                  <label for="levelDescription">Level start tekst</label>
                </div>

                <h6>Snelheid ({{ level.speed }}x)</h6>
                <div class="form-floating mt-2 mb-2">
                  <input type="range" v-model="level.speed" class="form-range" min="0.5" max="2" step="0.1">
                </div>

                <h6>Level up instellingen</h6>
                <div class="input-group">
                  <div class="form-floating mb-2">
                    <input type="number" min="0" step="1" v-model="level.wrongAnswersBeforeLevelDown"
                      class="form-control" placeholder="10" id="wrongAnswersBeforeLevelDown">
                    <label for="wrongAnswersBeforeLevelDown">
                      <fa icon="fa-solid fa-heart" /> Levens
                    </label>
                  </div>

                  <div class="form-floating mb-2">
                    <input type="number" min="0" step="1" v-model="level.rightAnswersBeforeLevelUp" class="form-control"
                      placeholder="10" id="rightAnswersBeforeLevelUp">
                    <label for="rightAnswersBeforeLevelUp">
                      <fa icon="fa-solid fa-trophy" /> Level omhoog bij
                    </label>
                  </div>

                  <div class="form-floating mb-2">
                    <input type="number" min="0" step="1" v-model="level.maxLevelTime" class="form-control"
                      placeholder="Lengte" id="maxLevelTime">
                    <label for="maxLevelTime">
                      <fa icon="fa-solid fa-stopwatch" /> Max. tijd
                    </label>
                  </div>
                </div>

                <div class="input-group">
                  <div class="form-floating mb-2">
                    <input type="number" min="0" step="1" v-model="level.location"
                      class="form-control" placeholder="0" id="location">
                    <label for="location">
                      <fa icon="fa-solid fa-mountain-sun" /> Locatie {{ (level.location > 0) ? '(specifiek)' : '(automatisch)' }}
                    </label>
                  </div>              
                </div>
              </div>
              <div class="animateIn col-lg-6 col-sm-12" v-if="level.goals.length > 0 && level.editingGoal != null">
                <ExplicitQuestionsAndAnswers
                  v-if="game.id == 'dancemath' || game.id == 'numdrummer' || game.id == 'skibooster'"
                  :editingGoal="level.editingGoal" :levelIndex="levelIndex" :questionTemplate="getNewQuestion()"
                  @propertyChanged="setGoalProperty" 
                />

                <SpeechLevelSettings v-else-if="game.id == 'echo'" :editingGoal="level.editingGoal"
                  :levelIndex="levelIndex" :gameIndex="gameIndex" 
                  @propertyChanged="setGoalProperty"
                  @answersCountChanged="changeAnswersCount"
                />

                <RightAndWrongOptions v-else-if="game.id == 'monsterchef'" :editingGoal="level.editingGoal"
                  :levelIndex="levelIndex" :questionTemplate="getNewQuestion()" />

                <InlineQuestionsAndExplanations v-else-if="game.id == 'wordpirates' || game.id == 'arrowgram'"
                  :editingGoal="level.editingGoal" :levelIndex="levelIndex" :questionTemplate="getNewQuestion()" />

                <QuestionsExplanations v-else-if="game.id == 'ninjakitchen'" :editingGoal="level.editingGoal"
                  :levelIndex="levelIndex" :questionTemplate="getNewQuestion()" />
                  
                <EggPackerLevelSettings v-else-if="game.id == 'eggpacker'" :editingGoal="level.editingGoal"
                  :levelIndex="levelIndex" :questionTemplate="getNewQuestion()" />

                <MultiplyLevelSettings v-else-if="game.id == 'multi'" :editingGoal="level.editingGoal"
                  :levelIndex="levelIndex" :questionTemplate="getNewQuestion()" />

                <div v-else>
                  Dit speel heeft geen instelbare levels.<br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End of game-->

    </form>
  </div>
  <div v-if="this.$store.state.user.admin" class="admin smoothContainer">
    <h5 role="button" class="user-select-none" @click="openedSettingTemplateList = !openedSettingTemplateList"
      :class="{'mb-0':!openedSettingTemplateList}">
      <fa icon="fa-solid fa-graduation-cap" /> Alle doel instelling templates
      <a href="#" role="button" class="text-decoration-none me-2 ms-2 float-end h5">
        <fa icon="fa fa-angle-up" v-if="openedSettingTemplateList" />
        <fa icon="fa fa-angle-down" v-else />
      </a>
    </h5>

    <div v-if="openedSettingTemplateList">
      <div class="justify-content-end mb-md-3" v-if="allowChangeOrder">  
        <div class="form-check form-switch mt-3 mb-3">
          <input class="form-check-input" type="checkbox" id="alphabetSwitch" v-model="sortByOrder"
            v-change="sortTemplates()">
          <label class="form-check-label" for="alphabetSwitch">Volgorde bepalen</label>
        </div>
        <button v-if="sortByOrder" class="btn btn-primary" @click="saveOrder">Volgorde opslaan</button>
      </div>

      <ul class="list-group animateIn">
        <a class="animateIn text-decoration-none" v-for="(settingTemplate, settingTemplateIndex) in settingTemplates"
          :key="settingTemplateIndex">
          <li class="list-group-item" :class="{ 'activeTemplate': settingTemplate.id == editTemplateId }">
            <a href="#" @click="editSettingTemplate(settingTemplate)" class="text-decoration-none">
              <fa icon="fa-solid fa-eye-slash" v-if="settingTemplate.hidden" /> 
              {{ settingTemplate.name }} <span v-show="settingTemplate.label && settingTemplate.label.length>0" class="badge bg-warning me-1">{{ settingTemplate.label }}</span>
            </a>
            <a href="#" class="tinyRemove text-danger ms-3" @click="removeSettingTemplate(settingTemplate)">
              <fa icon="fa-solid fa-remove" />
            </a>

            <span class="float-end rn" v-if="settingTemplate.rn">{{ settingTemplate.rn }}</span>
            <span class="float-end year" v-if="settingTemplate.year">{{ settingTemplate.year }}</span>

            <!-- arrow buttons to change settingTemplate order -->
            <div v-if="sortByOrder" class="float-end">  
              <a v-if="settingTemplateIndex < settingTemplates.length - 1" role="button"
                class="text-decoration-none me-2 ms-2 float-end"
                @click="moveTemplateOrderDown(settingTemplateIndex)">
                <fa icon="fa-solid fa-arrow-down" />
              </a>       
              <a v-if="settingTemplateIndex > 0" role="button" 
                class="text-decoration-none me-2 ms-2 float-end"
                @click="moveTemplateOrderUp(settingTemplateIndex)">
                <fa icon="fa-solid fa-arrow-up" />
              </a> 
            </div>
          </li>
        </a>
      </ul>
    </div>
  </div>
</template>

<script>
import { auth, db, firestore } from '@/main';
import TagInput from './Games/TagInput.vue';
import QuestionsExplanations from './Games/QuestionsExplanations.vue';
import InlineQuestionsAndExplanations from './Games/InlineQuestionsAndExplanations.vue';
import RightAndWrongOptions from './Games/RightAndWrongOptions.vue';
import ExplicitQuestionsAndAnswers from './Games/ExplicitQuestionsAndAnswers.vue';
import EggPackerLevelSettings from './Games/EggPackerLevelSettings.vue';
import MultiplyLevelSettings from './Games/MultiplyLevelSettings.vue';
import SpeechLevelSettings from './Games/SpeechLevelSettings.vue';

export default {
  name: 'GamesSettings',
  components:{
    TagInput,
    QuestionsExplanations,
    EggPackerLevelSettings,
    MultiplyLevelSettings,
    InlineQuestionsAndExplanations,
    RightAndWrongOptions,
    ExplicitQuestionsAndAnswers,
    SpeechLevelSettings
  },
  data(){
    return {
      editTemplateId: "",
      selectedGame:"",
      goalVisible: false,
      goalAlwaysLocked: false,
      newGoalsSettingName:"",
      newGoalsSettingLabel:"",
      newGoalsSettingYear:"8",
      newGoalsSettingRn:"1F",
      newGoalsSettingLineId:"",
      newGoalSettingsDomainId: "",
      newGoalSettingsSubdomainId: "",
      newGoalsSettingDescription:"",
      newGoalGameUnlockMode: 0,

      domains: [],
      filteredLines: [],
      searchLineInput: "",
      levelsAsJSONCopy: "",
      copySourceGameID: "",
      settingTemplates:[],
      sortByOrder: false, //By order field or Alfabetic
      allowChangeOrder: false,
      openedSettingTemplateList: false,
      selectedGames:[],
      defaultGoal: {
        id: "default",
        name: "default",
        randomAdd: 3,
        explicit: "",
        randomize: false,
        free: false,
        helpers: true,
        questions: [],
        explanations: []
      },
      goals:[], //Goals are now deprecated. So the default goal is always used for a new level.
      games:[],
      savingSettings: false,
      gameSettingGroups: [
          ["dancemath", "numdrummer", "skibooster"], //Some games can share the same type of settings
          ["wordpirates", "arrowgram" ],
          ["ninjakitchen"],
          ["monsterchef"],
          ["eggpacker"],
          ["multi"],
          ["echo"]
      ]
    }
  },
  computed:{
    getLineDomain(lineId){
      //Return domain inside domains
      for (let i = 0; i < this.domains.length; i++) {
        const domain = this.domains[i];
        for (let j = 0; j < domain.subdomains.length; j++) {
          const subdomain = domain.subdomains[j];
          for (let k = 0; k < subdomain.lines.length; k++) {
            const line = subdomain.lines[k];
            if(line.id == lineId){
              return domain;
            }
          }
        }
      }
      return null; // Add this line to return null if no domain is found
    },
    getLineSubdomain(lineId)
    {
      //Return subdomain inside domains/subdomain
      for (let i = 0; i < this.domains.length; i++) {
        const domain = this.domains[i];
        for (let j = 0; j < domain.subdomains.length; j++) {
          const subdomain = domain.subdomains[j];
          for (let k = 0; k < subdomain.lines.length; k++) {
            const line = subdomain.lines[k];
            if(line.id == lineId){
              return subdomain;
            }
          }
        }
      }
      return null; // Add this line to return null if no subdomain is found
    },
    getLineById(){
      //Return line inside domains/subdomain/lines
      for (let i = 0; i < this.domains.length; i++) {
        const domain = this.domains[i];
        for (let j = 0; j < domain.subdomains.length; j++) {
          const subdomain = domain.subdomains[j];
          for (let k = 0; k < subdomain.lines.length; k++) {
            const line = subdomain.lines[k];
            if(line.id == this.newGoalsSettingLineId){
              return line;
            }
          }
        }
      }
      return null; // Add this line to return null if no line is found
    }
  },
  methods:{
    saveOrder()
    {
      console.log("Save order of setting templates");
      let batch = db.batch();
      this.settingTemplates.forEach((settingTemplate, index) => {
        let docRef = db.collection("settingTemplates").doc(settingTemplate.id);
        batch.update(docRef, { order: index }, { merge: true });
      });
      batch.commit().then(() => {
        console.log("Order saved");
      });
    },
    connectToLine(line){
      this.searchLineInput = "";
      this.filteredLines = [];
      this.newGoalsSettingLineId = line.id;
      this.newGoalSettingsDomainId = line.domainId;
      this.newGoalSettingsSubdomainId = line.subdomainId;
    },
    disconnectLine(){
      this.searchLineInput = "";
      this.filteredLines = [];
      this.newGoalsSettingLineId = "";
    },
    moveTemplateOrderUp(settingTemplateIndex){
      if(settingTemplateIndex > 0){
        //move item with settingTemplateIndex in array one up with splice method
        this.settingTemplates.splice(settingTemplateIndex - 1, 0, this.settingTemplates.splice(settingTemplateIndex, 1)[0])
        
        this.applyIndexToOrder();
      }
    },
    moveTemplateOrderDown(settingTemplateIndex){
      if(settingTemplateIndex < this.settingTemplates.length - 1){
        //move item with settingTemplateIndex in array one down with splice method
        this.settingTemplates.splice(settingTemplateIndex + 1, 0, this.settingTemplates.splice(settingTemplateIndex, 1)[0])

        this.applyIndexToOrder();
      }
    },
    applyIndexToOrder(){
      for (let i = 0; i < this.settingTemplates.length; i++) {
        this.settingTemplates[i].order = i;
      }
    },
    sortTemplates(){
      if(this.sortByOrder){
        this.settingTemplates.sort((a, b) => a.order - b.order);
      }
      else{
        this.settingTemplates.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    getNewQuestion(){
      return {
        //Shared for games using questions list
        question: "", //Question in text form
        explanation: "", //Optional explanation if question is answered incorrectly
        tools:"", //Optional tools to use to answer the question

        //Specific for games like Kitchen Ninja
        answer: 0.0, //Answer numeric
        amount: 1, //Amount of times answer is needed
        timer: 0, //Time in seconds to answer (optional)
        type:0, //Answer type
        helpers: true, //Show help
        advanced: false, //Show advanced help

        //Optional list of multiple right and wrong answers (Monster chef specifics atm.)
        rightOptions: [0],
        wrongOptions: [0],           
        rightOptionsText: "",
        wrongOptionsText: "",
      }
    },
    selectGoal(level,goal)
    {
        level.editingGoal = goal;
    },
    setGoalProperty(goal, property, value) {
      console.log("Set goal property " + property + " to " + value);
      goal[property] = value;
    },
    changeAnswersCount(gameIndex, levelIndex, count) {
      var level = this.selectedGames[gameIndex].levels[levelIndex];
      level.rightAnswersBeforeLevelUp = count;
    },
    removeGoal(level,goal){
      let removeIndex = level.goals.indexOf(goal);
      level.goals.splice(removeIndex,1);
      level.editingGoal = null;
      goal = null;
    },  
    forceToAnswersArray(input){
      //Turn tabs and spaces into commas
      let cleaned = input.replace(/[\t]+/g, ",");

      //Turn backslash into forward slashes
      cleaned = input.replace(/\\/g, "/");

      return cleaned;
    }, 
    arrayStringToNumbers(input){
      console.log("Convert to number string: " + input);

      //Clear whitespace
      let _numbers = input.split(",").filter(function (x) {
        return !isNaN(x);
      });

      console.log(_numbers);
      return _numbers;
    }, 
    removeSettingTemplate(template){
      if(confirm("Weet je zeker dat je dit template wil verijderen?\nAlle sessies die gebruik maken van dit template zullen worden verwijderd."))
      {
        this.deleteTemplateGames(template.id);

        db.collection("settingTemplates").doc(template.id).delete().then(()=>{
          this.getTemplates();
        });
      }
    },  
    editSettingTemplate(settingTemplate){
      this.newGoalsSettingName = settingTemplate.name;
      this.newGoalsSettingLabel = settingTemplate.label || "";
      this.newGoalsSettingYear = settingTemplate.year || "";
      this.newGoalsSettingRn = settingTemplate.rn || "";
      this.newGoalSettingsDomainId = settingTemplate.domainId || "";
      this.newGoalSettingsSubdomainId = settingTemplate.subdomainId || "";
      this.newGoalsSettingLineId = settingTemplate.lineId || "";
      this.newGoalGameUnlockMode = settingTemplate.gameUnlockMode || 0;
      this.goalVisible = !settingTemplate.hidden;
      this.goalAlwaysLocked = settingTemplate.alwaysLocked || false;
      this.newGoalsSettingDescription = settingTemplate.description;
      this.editTemplateId = settingTemplate.id;

      //Load the games in setting
      db.collection("settingTemplates").doc(settingTemplate.id).collection("games").get().then((items) =>
      {
          let _games = [];
          items.forEach((item) => {
            //Each game in settings temlate list
            let game = item.data();
            game.id = item.id;
            game.order = game.order || 0;

            //Join data from actual game object to get the title and stuff
            let gameData = this.games.find(obj => obj.id === game.id);
            game.name = gameData.name;

            console.log("Game " + game.id);            
            //Replace levels with array with editable settings
            let levelCount = game.levels;
            console.log("Levels " + levelCount);

            game.levels = [];
            for (let i = 0; i < levelCount; i++) {
              console.log("Loading level " + i + " for game " + game.id);
              this.addLevel(game);
              let level = game.levels[i];
              level.goals = [];
              console.log("game.levelIndices " + game.levelIndices.length);
              for(let j = 0; j < game.levelIndices.length; j++)
              {
                  let levelIndex = game.levelIndices[j];
                  console.log("Checking level index " + levelIndex);
                  //Check if this index belongs to this level
                  if(levelIndex == i)
                  {
                    //Get level global settings
                    let levelSpeed = 1;
                    let levelAnswers = 10;
                    let wrongAnswers = 0;
                    let location = 0;

                    let levelTime = 0;
                    let description = "";

                    if(game.speeds && game.speeds.length > j){
                      levelSpeed = game.speeds[j];
                    }
                    if(game.answers && game.answers.length > j){
                      levelAnswers = game.answers[j];
                    }
                    if(game.wronganswers && game.wronganswers.length > j){
                      wrongAnswers = game.wronganswers[j];
                    }
                    if(game.times && game.times.length > j){
                      levelTime = game.times[j];
                    }
                    if(game.descriptions && game.descriptions.length > j){
                      description = game.descriptions[j];
                    }
                    if(game.locations && game.locations.length > j){
                      location = game.locations[j];
                    }

                    level.speed = levelSpeed;
                    level.rightAnswersBeforeLevelUp = levelAnswers;
                    level.wrongAnswersBeforeLevelDown = wrongAnswers;
                    level.location = location;
                    level.maxLevelTime = levelTime / 60; //Seconds to minutes
                    level.description = description;

                    const goalId = game.goalIds[j];
                    const settingsJSON = game.settings[j];
                    const goalParsed = JSON.parse(settingsJSON);
                    console.log("Speed for this level " + levelSpeed);
                    console.log("Goal id for this level " + goalId);
                    console.log("Settings for this level " + settingsJSON);

                    this.addGoal(level);

                    //Apply extras from parsed settings
                    let newGoal = level.goals[level.goals.length-1]
                    Object.assign(newGoal, goalParsed, newGoal);
                  }
              }  
              game.levels[i] = level;            
            } 

            _games.push(game);
          });

          //Order _games based on order
          _games.sort((a, b) => a.order - b.order);

          this.selectedGames = _games;
      });
    }, 
    removeLevel(level,game){
      let removeIndex = game.levels.indexOf(level);
      game.levels.splice(removeIndex,1);
    }, 
    removeGame(game){
      let confirmed = confirm("Are you sure you want to remove this game?\nAll levels and settings will be removed.");
      if(!confirmed) return;

      let removeIndex = this.selectedGames.indexOf(game);
      this.selectedGames.splice(removeIndex,1);
    },
    addGoal(level){
      if(level.goals.length > 1) 
      {
        //Multiple goals per level are disabled for now (though they are technically allowed)
        return;
      }
      let copiedGoal = Object.assign({}, this.defaultGoal);

      //No name given for setting? Default to this selected goal.
      if(this.newGoalsSettingName=="")
      {
        this.newGoalsSettingName = copiedGoal.name;
      }

      //Add game settings to goal object (every goal in a level can have custom settings)
      copiedGoal.randomAdd = 3; //Allowed to add or substract from random answers
      copiedGoal.explicit = "";
      copiedGoal.randomize = false; //Randomize questions order
      copiedGoal.free = false; //Free sandbox/playfull mode with more freedom
      copiedGoal.helpers = true; //Show help
      copiedGoal.questions = [];
      copiedGoal.explanations = [];

      this.addQuestionToArray(copiedGoal.questions);

      level.editingGoal = copiedGoal;  
      level.goals.push(copiedGoal);
      level.filteredGoals = [];
    },
    addQuestionToArray(questions){
      console.log("Adding new question")
      questions.push(
        this.getNewQuestion()
      );
    },
    removeQuestion(questions, index){
      questions.splice(index,1);
    },
    searchLineByInput(){
      console.log("Search for: " + this.searchLineInput);

      if(this.searchLineInput.length > 0)
      {
        this.filteredLines = [];
        //Filter lines by name
        this.domains.forEach(domain => {
          domain.subdomains.forEach(subdomain => {
            subdomain.lines.forEach(line => {
              if(line.name.toLowerCase().indexOf(this.searchLineInput.toLowerCase()) > -1){
                this.filteredLines.push(line);
              }
            });
          });
        });
      }
      else
      {
        this.filteredLines = [];
      }
    },
    copyLevelsFromGame(game){
        console.log("Copy levels from game " + game.id);
        this.levelsAsJSONCopy = JSON.stringify(game.levels);
        this.copySourceGameID = game.id;
    },
    pasteLevels(game)
    {
        if(this.areInSameGroup(game,this.copySourceGameID))
        {
          alert("Kan deze instellingen niet plakken. Di spel heeft andere type instellingen." );
          return;
        }
        
        if(!game.levels || game.levels.length == 0 || confirm("Weet je zeker dat je alle levels van deze game wil vervangen met deze kopie?")){
          if(this.levelsAsJSONCopy != "")
          {
            game.levels = JSON.parse(this.levelsAsJSONCopy);
            this.levelsAsJSONCopy = "";
          }
        }
    },
    areInSameGroup(id1, id2) {
        for (const group of this.gameSettingGroups) {
            if (group.includes(id1) && group.includes(id2)) {
                return true; // Both IDs are in the same group
            }
        }
        return false; // IDs are not in the same group
    },
    addLevel(game)
    {
      let level = {
        description: "",
        speed: 1.0,
        rightAnswersBeforeLevelUp: 10,
        wrongAnswersBeforeLevelDown: 0,
        location: 0,
        maxLevelTime: 0,
        goals:[],
        filteredGoals: [],
        goalInput: "",
        editingGoal: null,
        settings: "{}" //json blob with settings for unity
      }
      game.levels.push(level);

      this.addGoal(level);
    },
    addGame(game)
    {
      if(game != "" && this.selectedGames.indexOf(game) < 0){
        this.selectedGames.push(game);
      }
      this.selectedGame = "";

      //A game needs at least one level. Lets add it after adding the game.
      if(game.levels.length < 1){
        this.addLevel(game); 
      }
    },
    saveSettingTemplate()
    {
      console.log("Saving setting");
      this.savingSettings = true;
      const timestamp = firestore.FieldValue.serverTimestamp();

      // Iterate through each level and its goals
      // For now use one of the goal id's inside this template to allow quick filtering via domains
      let order = 0;

      //Determine what the max # of levels is within a game
      let maxLevels = 0;
      this.selectedGames.forEach((game) => {
        if(game.levels.length > maxLevels)
        {
          maxLevels = game.levels.length;
        }
      });

      let setting = {
        name: this.newGoalsSettingName,
        label: this.newGoalsSettingLabel,
        description: this.newGoalsSettingDescription,
        gameUnlockMode: this.newGoalGameUnlockMode,
        alwaysLocked: this.goalAlwaysLocked,
        domainId: this.newGoalSettingsDomainId,
        lineId: this.newGoalsSettingLineId,
        subdomainId: this.newGoalSettingsSubdomainId,
        hidden: !this.goalVisible,
        createdBy: auth.currentUser.uid,
        modified: timestamp,
        goalIds: ["default"],
        maxLevels: maxLevels,
        rn: this.newGoalsSettingRn,
        year: this.newGoalsSettingYear,
        order: order,
      };

      //Log to console what setting field is undefined
      console.log(JSON.stringify(setting));
      
      let settingsTemplateDocument;
      if(this.editTemplateId.length > 0)
      {
        //Overwrite existing setting template
        settingsTemplateDocument = db.collection("settingTemplates").doc(this.editTemplateId);
      }
      else{
        //Or create new one
        settingsTemplateDocument = db.collection("settingTemplates").doc();
      }

      settingsTemplateDocument.set(setting).then(() => {
          console.log("Setting successfully added! Now adding games.");

          let batch = db.batch();          

          //Add the games to the setting
          this.selectedGames.forEach((game) => {
            console.log("-Game:" + game.id);
            let _goalIds = [];
            let _goalSettings = [];
            let _levelIndices = [];
            let _locations = [];
            let _speeds = [];
            let _answers = [];
            let _wronganswers = [];
            let _times = [];
            let _descriptions = [];
            let _order = 0;

            //Determine game order based on index in selectedGames
            for (let i = 0; i < this.selectedGames.length; i++) {
              if (this.selectedGames[i].id === game.id) {
                _order = i;
                break;
              }
            }

            //We flatten the level goals and settings into arrays for the game document
            for(let i = 0; i < game.levels.length; i++)
            {
              let level = game.levels[i];
              console.log("----Level: " + i);
              for(let j = 0; j < level.goals.length; j++)
              {
                  let goal = level.goals[j];
                  console.log("-------Goal: " + goal.id);
                  _goalIds.push("default"); //All goals are default now (we do not use specific goals per level anymore)

                  //Sanitize settings jsons
                  goal.explicit = goal.explicit.replace(/[\n]/g, ",");
                  for (let i = 0; i < goal.questions.length; i++) {
                    const question = goal.questions[i];
                    question.rightOptions = question.rightOptionsText.split(",");
                    question.wrongOptions = question.wrongOptionsText.split(",");
                  }

                  let _settingJson = JSON.stringify(goal);
                  console.log("----------------Settings: ");
                  console.log(_settingJson);
                  _goalSettings.push(_settingJson);
                  
                  _levelIndices.push(i);
                  _speeds.push(level.speed); //Defaults to speed multiplier of 1
                  _answers.push(level.rightAnswersBeforeLevelUp); //Defaults to 10
                  _locations.push(level.location); //Defaults to 0
                  _wronganswers.push(level.wrongAnswersBeforeLevelDown); //
                  _times.push(level.maxLevelTime*60); //Defaults to 0 (disabled)
                  _descriptions.push(level.description || "");
              }
            }

            let _game = 
            {
              levels: game.levels.length,
              answers: _answers, //The # of answers to level up
              wronganswers: _wronganswers, //The # of wrong answers to level down
              locations: _locations, //The location of the level (some games can have multiple locations)
              times: _times, //The max time for the level ( 0 = off )
              goalIds: _goalIds, //array with goal ids
              descriptions: _descriptions, //The level descriptions
              levelIndices: _levelIndices, //The level the above items belong to, for example: [0,0,0,1,1,1,2,2,2]
              settings: _goalSettings, //array with json blobs for game specific settings
              speeds: _speeds, //The speed multiplier for the levels
              order: _order //The order of the game in the list, so we can use the order in the game
            };
            var docRef = settingsTemplateDocument.collection("games").doc(game.id);
            batch.set(docRef, _game);
          });
          batch.commit().then(() =>{
            //Clean up games docs in template that are no longer in selectedGames
            this.deleteTemplateGamesNotInList(settingsTemplateDocument.id, this.selectedGames.map(obj => obj.id));
            
            console.log("Added setting games and its goals");
            this.savingSettings = false;
            this.getTemplates();
          }).catch((error) => {
            this.savingSettings = false;
            console.error("Error adding document: ", error);
          });
      }).catch((error) => {
          this.savingSettings = false;
          console.error("Error adding document: ", error);
      });
    },
    deleteTemplateGames(templateId){
      db.collection("settingTemplates").doc(templateId).collection("games").get().then((items) => {
        items.forEach((item) => {
          db.collection("settingTemplates").doc(templateId).collection("games").doc(item.id).delete();
        });
      });
    },
    deleteTemplateGamesNotInList(templateId, gameIds){
      db.collection("settingTemplates").doc(templateId).collection("games").get().then((items) => {
        items.forEach((item) => {
          if(gameIds.indexOf(item.id) < 0){
            db.collection("settingTemplates").doc(templateId).collection("games").doc(item.id).delete();
          }
        });
      });
    },
    onLoadedGames(items) {
      let _games = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
        data.added = false;

        data.levels = [];

        _games.push(data);
      });
      this.games = _games;
    },
    onLoadedSettingTemplates(items) {
      let _settingTemplates = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
        _settingTemplates.push(data);
      });
      this.settingTemplates = _settingTemplates.filter(obj => obj.hidden).concat(_settingTemplates.filter(obj => !obj.hidden));
      this.sortTemplates();
    },
    getTemplates(){
      db.collection("settingTemplates").get().then(this.onLoadedSettingTemplates);
    },
    loadDomains()
    {
        //Load the subcollections into the domains array with this path domains/{domainId}/subdomains/{subdomainId}/lines/{lineId}
        db.collection("domains").get().then((items) => {
            let _domains = [];
            items.forEach((item) => {
              let _domain = item.data();
              _domain.id = item.id;
              _domain.subdomains = [];
              _domains.push(_domain);
            });

            //Load subdomains
            _domains.forEach((domain) => {
              db.collection("domains").doc(domain.id).collection("subdomains").get().then((items) => {
                let _subdomains = [];
                items.forEach((item) => {
                  let _subdomain = item.data();
                  _subdomain.id = item.id;
                  _subdomain.domainId = domain.id;
                  _subdomain.lines = [];
                  _subdomains.push(_subdomain);
                });

                //Load lines
                _subdomains.forEach((subdomain) => {
                  db.collection("domains").doc(domain.id).collection("subdomains").doc(subdomain.id).collection("lines").get().then((items) => {
                    let _lines = [];
                    items.forEach((item) => {
                      let _line = item.data();
                      _line.id = item.id;
                      _line.subdomainId = subdomain.id;
                      _line.domainId = domain.id;
                      _lines.push(_line);
                    });
                    subdomain.lines = _lines;
                  });
                });
                domain.subdomains = _subdomains;
              });
            });
            this.domains = _domains;
          }
        );
    }
  },
  mounted() {
    console.log(auth);
    db.collection("games").orderBy("name", "asc").get().then(this.onLoadedGames);
    this.getTemplates();
    this.loadDomains();
  }
}
</script>

<style context>
.activeTemplate{
  background-color: #e3f8ff;
}
.shared-textarea {
  resize: vertical;
  min-height: 120px !important;
}
.shared-textarea-small {
  resize: vertical;
  min-height: 90px !important;
}
.needsUpdate{
  background-color:rgb(255, 181, 154);
  margin-right:10px;
}
.levels{
  margin-right: 10px;
}

</style>